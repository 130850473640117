import { configureStore } from '@reduxjs/toolkit';
import userSlice from './login/userSlice';
import settingsSlice from './settings/settingsSlice';
import themeSlice from './themes/themeSlice';
import {combineReducers} from "redux"; 

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';


const rootReducer = combineReducers({ 
        user: userSlice,
        settings: settingsSlice,
        theme: themeSlice
  })

const persistConfig = {
    timeout: 1000,
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)
  
 export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE'],
            },
        }),
  })

  export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

