import React, { ReactNode, useState, useEffect, useRef } from "react";
import './pageWrapper.scss';
import { useTranslation } from "react-i18next";

import PageHeader from "../../Components/PageHeader/PageHeader";
import { PageHeaderParent } from "../../Common/Enums";

interface PageWrapperProps {
    title: string;
    pageHeaderParent: PageHeaderParent;
    displayRange: boolean;
    displaySettingsButton: boolean;
    settingsButtonTarget: string;
    children: ReactNode;

}

const PageWrapper: React.FC<PageWrapperProps> = ({ title, pageHeaderParent, children, displayRange = true, displaySettingsButton = false, settingsButtonTarget = ''}) => {

    return (
        <div className="control-section">
            <div className="wrapper-wrapper">
                <div className="wrapper-header">
                <PageHeader title={title} 
                            headerParent={pageHeaderParent} 
                            displayRange={displayRange} 
                            displaySettingsButton={displaySettingsButton} 
                            settingsButtonTarget={settingsButtonTarget} />
                </div>
                <div className="wrapper-container">
                    {children}
                </div>
            </div>
      </div>
    );
}

export default PageWrapper;