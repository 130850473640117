import * as React from "react";
import { useState, useRef, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { useTranslation } from "react-i18next";
import Content from "./Components/Content/Content";
import Appbar from "./Components/Appbar/Appbar";
import Sidebar from "./Components/Sidebar/Sidebar";
import RightSidebar from "./Components/RightSidebar/RightSidebar";
import { Suspense } from "react";
import { appRoutes } from './appRoutes';
import { store } from './state/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { RequireAuth } from "react-auth-kit";
import Login from "./Pages/Login/Login";
import { useAppDispatch, useAppSelector } from './Hooks/hooks';
import { ThemeProvider, useTheme } from './Contexts/ThemeContext';


import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
// import currencies from './Localization/currencies.json';
// import cagregorian from './Localization/ca-gregorian.json';
// import numbers from './Localization/numbers.json';
// import delimiters from './Localization/delimiters.json';



import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

// setCulture('hr-HR');
// setCurrencyCode('EUR');
// L10n.load({
//   'hr': {
//      'button': {
//           'check': 'vérifié'
//       }
//   }
// });
// loadCldr(
//   cagregorian,
//   currencies,
//   numbers,
//   delimiters,
//   // timeZoneNames,
//   // numberingSystems
// );

// loadCldr(
//   require('cldr-data/supplemental/numberingSystems.json'),
//   require('cldr-data/main/hr/ca-gregorian.json'),
//   require('cldr-data/main/hr/numbers.json'),
//   require('cldr-data/main/hr/timeZoneNames.json')
//    );

//    setCulture('hr');
//    setCurrencyCode('EUR');


let persistor = persistStore(store);

function App() {
  const [t, i18n] = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [theme, setTheme] = useState('light'); 


  const currentTheme = useAppSelector(state => state.theme.theme);

    useEffect(() => {
      setCulture('hr');
// setCurrencyCode('EUR');

L10n.load({
  'hr': {
     'button': {
          'check': 'vérifié'
      }
  }
});
      loadCldr(
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/main/hr/ca-gregorian.json'),
        require('cldr-data/main/hr/numbers.json'),
        require('cldr-data/main/hr/timeZoneNames.json'),
        require('cldr-data/main/hr/currencies.json'),
        require('cldr-data/main/hr/delimiters.json')
         );

         setCulture('hr');
         setCurrencyCode('EUR');

      setTheme(currentTheme);
        // if (currentTheme === 'dark') {
        //     require("@syncfusion/ej2-base/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-navigations/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-buttons/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-inputs/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-dropdowns/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-popups/styles/material-dark.css");
        //     require("@syncfusion/ej2-splitbuttons/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-layouts/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-calendars/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-notifications/styles/material-dark.css");
        //     require("@syncfusion/ej2-react-grids/styles/material-dark.css");
        //     // require("@syncfusion/ej2-react-charts/styles/material-dark.css");
        //     require("@syncfusion/ej2-icons/styles/material.css");
            
        // } else {
        //     require("@syncfusion/ej2-base/styles/material.css");
        //     require("@syncfusion/ej2-react-navigations/styles/material.css");
        //     require("@syncfusion/ej2-react-buttons/styles/material.css");
        //     require("@syncfusion/ej2-react-inputs/styles/material.css");
        //     require("@syncfusion/ej2-react-dropdowns/styles/material.css");
        //     require("@syncfusion/ej2-react-popups/styles/material.css");
        //     require("@syncfusion/ej2-splitbuttons/styles/material.css");
        //     require("@syncfusion/ej2-react-layouts/styles/material.css");
        //     require("@syncfusion/ej2-react-calendars/styles/material.css");
        //     require("@syncfusion/ej2-react-notifications/styles/material.css");
        //     require("@syncfusion/ej2-react-grids/styles/material.css");
        //     // require("@syncfusion/ej2-react-charts/styles/material.css");
        //     require("@syncfusion/ej2-icons/styles/material.css");
        // }
    }, [currentTheme]);

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  // useEffect(() => {
  //   console.log("THEME: "+theme);
  //   document.body.className = theme;
  // }, [theme]);


  const lng = navigator.language;
  const mediaQuery = "(min-width: 600px)";

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    // <Provider store={store}>
    <ThemeProvider theme={currentTheme}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route
              path={"/"}
              element={
                <RequireAuth loginPath={"/login"}>
                  <div className={`flex flex-col h-screen e-${theme}-mode`}>

                    {/* Header */}
                    <div className="w-full top-0 fixed z-10">
                      <Appbar onToggleSidebar={toggleSidebar} />
                    </div>


                    {/* <div style={{ marginTop: 150, marginBottom: 100, height: 200, backgroundColor: "black" }}> */}
                    {/* <ScheduleComponent height='550px' selectedDate={new Date(2018, 1, 15)} locale='fr-CH' >
    <ViewsDirective>
      <ViewDirective option='Day' />
      <ViewDirective option='Week' />
      <ViewDirective option='WorkWeek' />
      <ViewDirective option='Month' />
    </ViewsDirective>
    <Inject services={[Day, Week, WorkWeek, Month]} />
  </ScheduleComponent> */}

  {/* <DatePickerComponent id="datepicker" locale='hr' /> */}
                    {/* </div> */}

                    {/* Main Content */}
                    <div className="flex flex-1 bottom-0 overflow-hidden">
                      {/* Sidebar */}
                      {isSidebarOpen && (
                        <Sidebar
                          isSidebarOpen={isSidebarOpen}
                          mediaQuery={mediaQuery}
                        />
                      )}

                      {/* Content */}
                      <Content isSidebarOpen={isSidebarOpen} />
                      {/* <RightSidebar  /> */}
                    </div>
                  </div>
                </RequireAuth>
              }
            >
              {appRoutes.map((route) => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </Router>
      </PersistGate>
      </ThemeProvider>
    // </Provider>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}

// export default App;
