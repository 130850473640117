import { getSalesByMonth } from './salesDashboardAPI';
import { DashboardBasicDto, DashboardChartDto, KeyValueDto } from '../Dto/BaseDto';
import apiClient, { createApiClient, createApiClientWithHeaders, RequestType } from './apiClient';
import { getColorFromList, truncateString, formatNumber } from '../Utility/Utility';


  //Get stores list
  export const getStoresList = async(tenant: string): Promise<KeyValueDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/retailDashboard/storesList');
    const result: KeyValueDto[] = response.data;
    return result;
  }

  //Get sales amount in period by stores or for all stores if no stores parameter
  export const getSalesInPeriod = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
    // const client = createApiClientWithHeaders('app', tenant, RequestType.Tenant, {'Stores': JSON.stringify(stores)});
    const client = createApiClient('app', tenant, RequestType.Tenant);

    let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');

    // Build the URL with all query parameters.
    let url = `/retailDashboard/salesInPeriod?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
  
    // If there are stores to include, add them to the query string.
    if (stores.length > 0) {
      url += `&stores=${storesQueryParam}`;
    }

    const response = await client.get(url);

    const result: DashboardBasicDto = response.data;

    return result;
  };

  //Get number of retail invoices in period by stores or for all stores if no stores parameter
  export const getNumberOfInvoicesInPeriod = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);

    let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
    // Build the URL with all query parameters.
    let url = `/retailDashboard/numberOfInvoices?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
  
    // If there are stores to include, add them to the query string.
    if (stores.length > 0) {
      url += `&stores=${storesQueryParam}`;
    }

    const response = await client.get(url);

    const result: DashboardBasicDto = response.data;

    return result;
  };

  //Get net sales amount in period by stores or for all stores if no stores parameter
  export const getNetSalesInPeriod = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);

    let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
    // Build the URL with all query parameters.
    let url = `/retailDashboard/netSalesInPeriod?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
  
    // If there are stores to include, add them to the query string.
    if (stores.length > 0) {
      url += `&stores=${storesQueryParam}`;
    }

    const response = await client.get(url);

    const result: DashboardBasicDto = response.data;

    return result;
  };

  //Get sales VAT amount in period by stores or for all stores if no stores parameter
  export const getSalesVatInPeriod = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);

    let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
    // Build the URL with all query parameters.
    let url = `/retailDashboard/salesVatInPeriod?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
  
    // If there are stores to include, add them to the query string.
    if (stores.length > 0) {
      url += `&stores=${storesQueryParam}`;
    }

    const response = await client.get(url);

    const result: DashboardBasicDto = response.data;

    return result;
  };
  
    //Get top categories by amount and stores or for all stores if no stores parameter
    export const getTopCategories = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardChartDto[]> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
    
      // Build the URL with all query parameters.
      let url = `/retailDashboard/topCategories?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardChartDto[] = response.data;
  
      return result;
    };

     //Get top products by amount and stores or for all stores if no stores parameter
     export const getTopProducts = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardChartDto[]> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
    
      // Build the URL with all query parameters.
      let url = `/retailDashboard/topProducts?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardChartDto[] = response.data;
  
      return result;
    };


    export const getTopInvoiceAmounts = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardChartDto[]> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
    
      // Build the URL with all query parameters.
      let url = `/retailDashboard/topInvoiceAmounts?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardChartDto[] = response.data;
  
      return result;
    };

    export const getMarginTotal = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
      // Build the URL with all query parameters.
      let url = `/retailDashboard/marginTotal?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardBasicDto = response.data;
  
      return result;
    };

    export const getDiscountTotal = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
      // Build the URL with all query parameters.
      let url = `/retailDashboard/discountTotal?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardBasicDto = response.data;
  
      return result;
    };

    export const getAverageDiscountPercent = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
      // Build the URL with all query parameters.
      let url = `/retailDashboard/averageDiscountPercent?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardBasicDto = response.data;
  
      return result;
    };

    export const getAverageDiscountAmount = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
      // Build the URL with all query parameters.
      let url = `/retailDashboard/averageDiscountAmount?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardBasicDto = response.data;
  
      return result;
    };

    export const getSalesByMonthByStore = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardChartDto[]> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
    
      // Build the URL with all query parameters.
      let url = `/retailDashboard/salesByMonthByStore?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardChartDto[] = response.data;
  
      return result;
    };

    export const getSalesByMonthAllStores = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardChartDto[]> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
    
      // Build the URL with all query parameters.
      let url = `/retailDashboard/salesByMonthAllStores?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardChartDto[] = response.data;
  
      return result;
    };

    export const getNumberOfItemsSoldOnDiscount = async (tenant: string, fromDate: string, toDate: string, stores: string[] = []): Promise<DashboardBasicDto> => {
      const client = createApiClient('app', tenant, RequestType.Tenant);
  
      let storesQueryParam = stores.map(store => encodeURIComponent(store)).join(',');
  
      // Build the URL with all query parameters.
      let url = `/retailDashboard/numberOfItemsSoldOnDiscount?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    
      // If there are stores to include, add them to the query string.
      if (stores.length > 0) {
        url += `&stores=${storesQueryParam}`;
      }
  
      const response = await client.get(url);
  
      const result: DashboardBasicDto = response.data;
  
      return result;
    };