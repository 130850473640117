// import { UserData } from './../../Pages/Login/loginSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store';

export interface UserData {
    tenant: string,
    email: string,
    id: string,
    name: string,
    surname: string,
    tenantId: string
}

const initialState:  UserData = {
    tenant: '',
    email: '',
    id: '',
    name: '',
    surname: '',
    tenantId: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserData>) => {
            const {tenant, email, id, name, surname, tenantId} = action.payload;
            state.tenant = tenant;
            state.email = email;
            state.id = id;
            state.name = name;
            state.surname = surname;
            state.tenantId = tenantId;
        },
        clearUser: state => {
            state.tenant = '';
            state.email = '';
            state.id = '';
            state.name = '';
            state.surname = '';
            state.tenantId = '';
        }
    }
})


export const { setUser, clearUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;