import apiClient, { createApiClient, RequestType } from './apiClient';
import { DashboardDateRangeDto } from '../Dto/BaseDto';
import { SynAppValueSettingType } from '../Common/Enums';

export interface SynAppValueSetting {
    tenantId: string,
    userId: string,
    settingType: SynAppValueSettingType,
    settingValue: string
}

export const updateValueSetting = async (tenant: string, settingData: SynAppValueSetting) => {        
    const client = createApiClient('app', tenant, RequestType.Tenant);
    console.log("SETTING: " +settingData);
    await client.put('/synSettings/valueSetting', settingData);
}

export const insertValueSetting = async (tenant: string, settingData: SynAppValueSetting) => {    
    const client = createApiClient('app', tenant, RequestType.Tenant);
    await client.post('/synSettings/valueSetting', settingData);
}

export const getValueSetting = async (tenant: string, settingData: SynAppValueSetting): Promise<SynAppValueSetting> => {    
    const client = createApiClient('app', tenant, RequestType.Tenant);

    const response = await client.get('/synSettings/setting/'+ settingData.userId, {
        params: {
            type: settingData.settingType            
        }
    });
    const result: SynAppValueSetting = response.data;

    return result;
}

export const getValueSettingsByUser = async (tenant: string, settingData: SynAppValueSetting) => {    
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/synSettings/byUser/'+ settingData.userId);
}

