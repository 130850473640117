import { DashboardBasicDto, DashboardChartDto } from '../Dto/BaseDto';
import apiClient, { createApiClient, RequestType } from './apiClient';
import { getColorFromList, truncateString, formatNumber } from '../Utility/Utility';


  //Get number of invoices by period
  export const getNumberOfInvoices = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/numberOfInvoicesInPeriod?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

  //Get total sales by invoices by period
  export const getSalesInPeriod = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/salesInPeriod?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

  //Get total net (no tax) sales by invoices by period
  export const getNetSalesInPeriod = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/netSalesInPeriod?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

  //Get total tax by invoices by period
  export const getSalesVatInPeriod = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/salesVatInPeriod?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

  //Get top customers with sales amount
  export const getTopCustomers = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardChartDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/topCustomers?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardChartDto[] = response.data;

    const resultWithColors = result.map((item, index) => ({
      ...item,
      description: truncateString(item.description, 15),
      color: getColorFromList(index)
  }));

    return resultWithColors;
  }

  //Get top customers with sales amount
  export const getTopCategories = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardChartDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/topCategories?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardChartDto[] = response.data;

    const resultWithColors = result.map((item, index) => ({
      ...item,
      color: getColorFromList(index)
  }));

    return resultWithColors;
  }

  //Get top customers with sales amount
  export const getTopProducts = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardChartDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/topProducts?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardChartDto[] = response.data;

    const resultWithColors = result.map((item, index) => ({
      ...item,
      color: getColorFromList(index)
  }));

    return resultWithColors;
  }

  //Get number of offers by period
  export const getOffersNumberInPeriod = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/offersNumber?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

   //Get offers total by period
   export const getOffersTotalInPeriod = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/offersTotal?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

   //Get offers number realized by period
   export const getOffersRealizedNumberInPeriod = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/offersRealizedNumber?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

   //Get offers number realized by period
   export const getOffersRealizedTotalInPeriod = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardBasicDto> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/offersRealizedTotal?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardBasicDto = response.data;
    return result;
  }

  //Get top customers with sales amount
  export const getSalesByMonth = async(tenant: string, fromDate: string, toDate: string): Promise<DashboardChartDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/salesDashboard/salesByMonth?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: DashboardChartDto[] = response.data;

    const resultWithColors = result.map((item, index) => ({
      ...item,
      // amount: 10
      // amount: parseFloat(formatNumber(item.amount))
      // color: getColorFromList(index)
    }));

    return resultWithColors;
  }

