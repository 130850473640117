import axios from 'axios';  
import Cookies from 'js-cookie';

// const BASE_URL = 'https://localhost:44391/api/';
const BASE_API_ROUTE = "app";
// const BASE_LOGIN_URL = 'http://localhost:3000/login';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_LOGIN_URL = process.env.REACT_APP_BASE_LOGIN_URL;


export enum RequestType {
  Host = '2',
  Tenant = '3'
}

function getToken(){
    return Cookies.get('_auth');
}

const apiClient = axios.create({
    baseURL: BASE_URL + BASE_API_ROUTE,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`, 
        '__tenant': 'marta',
        '__requestType': '3'
    }
});

apiClient.interceptors.response.use(
  response => response,
  error => {
      if (error.response && error.response.status === 401) {
          // Redirect to login page or show modal
          console.log('Unauthorized: Redirecting to login page.');
          window.location.href = '/login'; //BASE_LOGIN_URL; // Ensure BASE_LOGIN_URL is defined
      }
      return Promise.reject(error);
  }
);


// apiClient.interceptors.response.use(
//   response => response,
//   error => {

//     console.log("ERRORRRRR: " + error.response.status)
//       if (error.response && [401, 403].includes(error.response.status)) {
//           // Redirect or handle unauthorized error
//           window.location.href = '/login';
//       }
//       return Promise.reject(error);
//   }
// );


// apiClient.interceptors.response.use(
//     function (response) {
//       return response;
//     }, 
//     function (error) {
//       let res = error.response;
//       if (res.status == 401) {

//         if (typeof BASE_LOGIN_URL === 'undefined') {
//           throw new Error('BASE_LOGIN_URL is not defined');
//         }
//         window.location.href = BASE_LOGIN_URL;

//       }
//       return Promise.reject(error);
//     }
//   );

  export default apiClient;

  
  export function createApiClient(apiBaseRoute: string = 'app', tenant: string, requestType: RequestType = RequestType.Tenant){
    const apiClient = axios.create({
      baseURL: BASE_URL + apiBaseRoute,
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`, 
          '__tenant': tenant,
          '__requestType': requestType
      }
    });
  
    apiClient.interceptors.response.use(
        function (response) {
          return response;
        }, 
        function (error) {
          let res = error.response;
          if (res.status == 401) {
            if (typeof BASE_LOGIN_URL === 'undefined') {
              throw new Error('BASE_LOGIN_URL is not defined');
            }
            window.location.href = BASE_LOGIN_URL;
    
          }
          return Promise.reject(error);
        }
      );

      return apiClient;
  }

  export function createApiClientWithHeaders(apiBaseRoute: string = 'app', tenant: string, requestType: RequestType = RequestType.Tenant, customHeaders: Record<string, string> = {}) {
    const apiClient = axios.create({
      baseURL: BASE_URL + apiBaseRoute,
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`, 
          '__tenant': tenant,
          '__requestType': requestType,
          ...customHeaders // Spread custom headers into the headers object
      }
    });
  
    apiClient.interceptors.response.use(
        function (response) {
          return response;
        }, 
        function (error) {
          let res = error.response;
          if (res.status == 401) {
            if (typeof BASE_LOGIN_URL === 'undefined') {
              throw new Error('BASE_LOGIN_URL is not defined');
            }
            window.location.href = BASE_LOGIN_URL;
          }
          return Promise.reject(error);
        }
      );
  
      return apiClient;
  }
  