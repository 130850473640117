import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  AppBarComponent,
  MenuComponent,
  MenuItemModel,
} from "@syncfusion/ej2-react-navigations";
import { DropDownButtonComponent, MenuEventArgs } from "@syncfusion/ej2-react-splitbuttons";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent, SelectEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { useTranslation } from "react-i18next";
import { getDateRange, DateRange, formatDateForAPI, formatShortDate } from '../../Utility/Utility';
import { DashboardDateRangeDto } from '../../Dto/BaseDto';
import { updateValueSetting, getValueSetting, SynAppValueSetting } from "../../api/synSettingsAPI";
import { useAppSelector, useAppDispatch } from '../../Hooks/hooks';
import './pageHeader.scss';
import { setSetting } from "../../state/settings/settingsSlice";
import DateRangeDialog from "../DateRangeDialog/DateRangeDialog";
import { UserData } from "../../state/login/userSlice";
import { PageHeaderParent, SynAppValueSettingType } from "../../Common/Enums";

interface PageHeaderProps {
    isVisible?: boolean;
    title: string;
    headerParent?: PageHeaderParent;
    displayRange: boolean;
    displaySettingsButton: boolean;
    settingsButtonTarget: string;
}

interface CustomItemData {
  id: string,
  period: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ isVisible, title, headerParent,  displayRange = true, displaySettingsButton = false, settingsButtonTarget = ''}) => {
    const [t, i18n] = useTranslation();
    const [selectedRange, setSelectedRange] = useState('1');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCustomDateRange, setIsCustomDateRange] = useState(false);
    const [startDateState, setStartDateState] = useState<Date>(new Date());
    const [endDateState, setEndDateState] = useState<Date>(new Date());
    
    const user: UserData = useAppSelector(state => state.user);
    const tenant: string = useAppSelector(state => state.user.tenant);
    const dispatch = useAppDispatch();   

    //Get the date range settings based in the settings in store
    let dateRangeSettingIndex: number;
    switch (headerParent) {
        case PageHeaderParent.SalesDashboard:
            dateRangeSettingIndex = 1;
            break;
        case PageHeaderParent.RetailDashboard:
            dateRangeSettingIndex = 3;
            break;
        case PageHeaderParent.OffersList:
              dateRangeSettingIndex = 6;
              break;
        case PageHeaderParent.InvoicesList:
              dateRangeSettingIndex = 7;
              break;
        default:
            dateRangeSettingIndex = 1;
            break;
    }
    //Load drop down setting from store
    let dateRangeSetting = useAppSelector(state => state.settings.settings[dateRangeSettingIndex]);

    useEffect(() => {
      if (dateRangeSetting){
        const data: DashboardDateRangeDto = JSON.parse(dateRangeSetting.settingValue);
        setStartDateState(new Date(data.fromDate));
        setEndDateState(new Date(data.toDate));
        setSelectedRange(data.rangeType);

        if (data.rangeType == '11'){
          setIsCustomDateRange(true);
        }
        else {
          setIsCustomDateRange(false);
        }
      }

    }, [dateRangeSetting]);
    
    const periodsData: {[key: string]: Object}[] = [
      {id: '1', period: i18n.t('pageHeader.filterPeriods.today')},
      {id: '2', period: i18n.t('pageHeader.filterPeriods.yesterday')},
      {id: '3', period: i18n.t('pageHeader.filterPeriods.thisWeek')},
      {id: '4', period: i18n.t('pageHeader.filterPeriods.lastWeek')},
      {id: '5', period: i18n.t('pageHeader.filterPeriods.thisMonth')},
      {id: '6', period: i18n.t('pageHeader.filterPeriods.lastMonth')},
      {id: '7', period: i18n.t('pageHeader.filterPeriods.thisQuarter')},
      {id: '8', period: i18n.t('pageHeader.filterPeriods.lastQuarter')},
      {id: '9', period: i18n.t('pageHeader.filterPeriods.thisYear')},
      {id: '10', period: i18n.t('pageHeader.filterPeriods.lastYear')},
      {id: '11', period: !isCustomDateRange ? i18n.t('pageHeader.filterPeriods.pickDates') : formatShortDate(startDateState) + ' - ' + formatShortDate(endDateState)}
    ]

    const fields: Object = { text: 'period', value: 'id'};

    //Handle date range selection
    const periodItemSelect = useCallback((args: SelectEventArgs) => {
      const itemData = args.itemData as unknown as CustomItemData;
      const id: number = parseInt(itemData.id);

      if (id in DateRange){

        //Open dialog for date selection when custom selected
        if (id == DateRange.Custom) {
          setIsDialogOpen(true);
          return;
        }

        const period = id as DateRange;
        const { startDate, endDate } = getDateRange(period);

        setIsCustomDateRange(false);
        setDateRange(period, user, startDate, endDate);  
      }
    }, []);

    const handleDialogClose = () => {
      setIsDialogOpen(false);
    } 

    //Handle Custom date range selection
    const handleDateRangeSubmit = (dateFrom: Date, dateTo: Date) => {
      setIsCustomDateRange(true);
      setDateRange(DateRange.Custom, user, dateFrom, dateTo);
    };

    function setDateRange(period: DateRange, user: UserData, fromDate: Date, toDate: Date) {
        const formattedStartDate = formatDateForAPI(fromDate);
        const formattedEndDate = formatDateForAPI(toDate);

        const data: DashboardDateRangeDto = {
          rangeType: period.toString(),
          fromDate: formattedStartDate,
          toDate: formattedEndDate
        }
        
        let settingType: SynAppValueSettingType;
        switch (headerParent) {
          case PageHeaderParent.SalesDashboard:
            settingType = SynAppValueSettingType.SalesDashboardDateRange;
            break;
          case PageHeaderParent.RetailDashboard:
            settingType = SynAppValueSettingType.RetailDashboardDateRange;
            break;
          case PageHeaderParent.OffersList:
            settingType = SynAppValueSettingType.OffersListDateRange;
            break;
          case PageHeaderParent.InvoicesList:
            settingType = SynAppValueSettingType.InvoicesListDateRange;
            break;
          default:
            settingType = SynAppValueSettingType.None
            break;
        }

        const settingToUpdate: SynAppValueSetting = {
          tenantId: user.tenantId,
          userId: user.id,
          settingType: settingType,
          settingValue: JSON.stringify(data),
        } 

        updateValueSetting(tenant, settingToUpdate);
        dispatch(setSetting(settingToUpdate));
    }

    const handleDateRangeDialogOpen = () => {
      setIsDialogOpen(true);
    }

    return (
        <>
        {isDialogOpen && (
                <DateRangeDialog
                    visible={isDialogOpen}
                    fromDate={startDateState}
                    toDate={endDateState}
                    onClose={handleDialogClose}
                    onSubmit={handleDateRangeSubmit}
                />
            )}
        <AppBarComponent isSticky cssClass="page-header">
            <span className="page-header-title">{title}</span>
            <div className="e-appbar-spacer"></div>

            {displayRange && (
                <>
                  <DropDownListComponent id='ddlelement' width={200} dataSource={periodsData} 
                                    fields={fields} placeholder="Select a period" value={selectedRange}
                                    select={periodItemSelect} />
            
            
                  {isCustomDateRange && (
                    <ButtonComponent
                    onClick={handleDateRangeDialogOpen}
                    className="e-inherit menu ml-2"
                    cssClass=""
                    iconCss="e-icons e-medium e-date-range"
                  ></ButtonComponent>
                  )}
                </>
            )}

            {displaySettingsButton && (
                <ButtonComponent
                className="e-inherit menu ml-2"
                cssClass=""
                iconCss="e-icons e-medium e-settings"
              ></ButtonComponent>
            )}
        </AppBarComponent>
        </>
    )
}

export default PageHeader;