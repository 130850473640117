import React, { useState, ChangeEvent } from "react";
import { useSignIn } from "react-auth-kit";

import "./login.scss";
import { useTranslation } from "react-i18next";
import { login, getUserData } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import { UserDataDto, ThemeDto } from '../../Dto/BaseDto'
import { getValueSetting, insertValueSetting, SynAppValueSetting } from "../../api/synSettingsAPI";
import { SynAppValueSettingType } from '../../Common/Enums';

import { useAppSelector, useAppDispatch } from '../../Hooks/hooks';
import { setUser } from '../../state/login/userSlice';
import { setSetting } from '../../state/settings/settingsSlice';
import { setTheme } from '../../state/themes/themeSlice';

interface LoginState {
  tenant: string;
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginState, setLoginState] = useState<LoginState>({
    tenant: "",
    username: "",
    password: "",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof LoginState
  ) => {
    setLoginState((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const handleLogin = async () => {
    try {
      const result = await login(loginState);      

      await signIn({
        token: result.access_token,
        tokenType: result.token_type,
        expiresIn: result.expires_in,
        authState: { email: loginState.username },
      });

      // Attempt to get user data with retry logic
      const getUserDataWithRetry = async (retries = 3): Promise<UserDataDto> => {
        for (let attempt = 1; attempt <= retries; attempt++) {
          try {
            const userData = await getUserData(loginState.tenant, loginState.username);
            userData.tenant = loginState.tenant;
            return userData;
          } catch (error: any) {
            if (error.response?.status === 401 && attempt < retries) {
              console.warn(`Attempt ${attempt} failed, retrying...`);
              await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retrying
            } else {
              throw error;
            }
          }
        }
        throw new Error('Failed to fetch user data after multiple attempts');
      };

    // const userData = await getUserDataWithRetry();
    // dispatch(setUser(userData));

      const userData = await getUserDataWithRetry();//await getUserData(loginState.tenant, loginState.username);
      userData.tenant = loginState.tenant;
      dispatch(setUser(userData));

      //Store Sales dashboard Date Range settings
      const salesDashboardDateRangeSetting = {
        tenantId: userData.tenantId,
        userId: userData.id,
        settingType: SynAppValueSettingType.SalesDashboardDateRange,
        settingValue: '',
      }
      getValueSetting(userData.tenant, salesDashboardDateRangeSetting)
                          .then(response => {
                            dispatch(setSetting(response))
                          })
                          .catch(error => {
                            console.log(error);
                          });

      //Store Retail dashboard Date Range settings
      const retailDashboardDateRangeSetting = {
        tenantId: userData.tenantId,
        userId: userData.id,
        settingType: SynAppValueSettingType.RetailDashboardDateRange,
        settingValue: '',
      }
      getValueSetting(userData.tenant, retailDashboardDateRangeSetting)
                          .then(response => {
                            dispatch(setSetting(response))
                          })
                          .catch(error => {
                            console.log(error);
                          });

      //Offers List Date Range settings
      const offersDateRangeSetting = {
        tenantId: userData.tenantId,
        userId: userData.id,
        settingType: SynAppValueSettingType.OffersListDateRange,
        settingValue: '',
      }
      getValueSetting(userData.tenant, offersDateRangeSetting)
                          .then(response => {
                            dispatch(setSetting(response))
                          })
                          .catch(error => {
                            console.log(error);
                          });

      //Invoices List Date Range settings
      const invoicesDateRangeSetting = {
        tenantId: userData.tenantId,
        userId: userData.id,
        settingType: SynAppValueSettingType.InvoicesListDateRange,
        settingValue: '',
      }
      getValueSetting(userData.tenant, invoicesDateRangeSetting)
                          .then(response => {
                            dispatch(setSetting(response))
                          })
                          .catch(error => {
                            console.log(error);
                          });
     
      //Store Theme settings
      const themeSetting = {
        tenantId: userData.tenantId,
        userId: userData.id,
        settingType: SynAppValueSettingType.UserTheme,
        settingValue: '{"theme":"light"}',
      }
      getValueSetting(userData.tenant, themeSetting)
                      .then(response => {
                        if (response) {
                          const t: ThemeDto = JSON.parse(response.settingValue);
                          dispatch(setTheme(t));
                        }
                        else {
                          insertValueSetting(userData.tenant, themeSetting);
                          const t: ThemeDto = JSON.parse(themeSetting.settingValue);
                          dispatch(setTheme(t));
                        }
                      })
                      .catch(error => {
                        console.log(error);
                      });

      navigate('/');

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="login-form-wrapper items-center justify-center">
          <div className="login-form-header">
            <span className="header-text">{t("login.headerMessage")}</span>
          </div>

          <div className="login-form bg-white p-8 rounded-lg shadow-md">
            <div className="">
              <label
                className="block text-gray-700 text-md font-bold mb-2"
                htmlFor="username"
              >
                {t("login.tenant")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="tenantName"
                placeholder="Username"
                type="text"
                value={loginState.tenant}
                onChange={(e) => handleInputChange(e, "tenant")}
              ></input>
            </div>
            <div className="mb-4 mt-4">
              <label
                className="block text-gray-700 text-md font-bold mb-2"
                htmlFor="username"
              >
                {t("login.username")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                placeholder="Username"
                type="text"
                value={loginState.username}
                onChange={(e) => handleInputChange(e, "username")}
              ></input>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                {t("login.password")}
              </label>
              <input
                className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="******************"
                value={loginState.password}
                onChange={(e) => handleInputChange(e, "password")}
              ></input>
              <p className="text-red-500 text-xs italic">
                {t("login.pwdMessage")}
              </p>
            </div>
            <div className="flex items-center justify-between mt-6">
              <button
                id="logIn"
                className="bg-blue-800 hover:bg-blue-700 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleLogin}
              >
                {t("login.signIn")}
              </button>
            </div>
            <div className="flex items-center justify-between mt-2">
              <a
                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                href="#"
              >
                {t("login.forgotPassword")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
