import { DashboardBasicDto, DashboardChartDto } from '../Dto/BaseDto';
import apiClient, { createApiClient, RequestType } from './apiClient';
import { getColorFromList, truncateString, formatNumber } from '../Utility/Utility';


export interface OfferDto {
    din: string;
    offer_Number: number;
    offer_Date: string;
    customer_Name: string;
    id: number;
    vat_InvoiceAmmount: number;
}

export const getOffersList = async(tenant: string, fromDate: string, toDate: string): Promise<OfferDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/offers/offers?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: OfferDto[] = response.data;
    return result;
  }