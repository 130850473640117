import React, { useContext, createContext, ReactNode } from 'react';

type ThemeProviderProps = {
    children: ReactNode;
    theme: string;
};

export const ThemeContext = createContext<string>('light');

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children, theme }: ThemeProviderProps) => (
    <ThemeContext.Provider value={theme}>
        {children}
    </ThemeContext.Provider>
);

