import React, { useState, useEffect, useRef } from "react";
import './partnersList.scss';
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '../../../Hooks/hooks';

import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group } from '@syncfusion/ej2-react-grids';
import { Inject, Page, PageSettingsModel, Sort, SortSettingsModel, FilterSettingsModel, LoadingIndicatorModel } from '@syncfusion/ej2-react-grids';

import { getPartnersList, PartnerDto } from '../../../api/partnersAPI';
import { GeneralDateRangeDto } from '../../../Dto/BaseDto'
import { PageHeaderParent, SynAppValueSettingType } from "../../../Common/Enums";

import { gridData } from '../../../Data/testData';



const PartnersList = () => {
    const [t, i18n] = useTranslation();
    const [ partnersList, setPartnersList ] = useState<PartnerDto[]>([]);

    const user = useAppSelector(state => state.user);

    useEffect(() => {
        const fetchData = async () => {
          try {           
            
              const results = await Promise.all([
                getPartnersList(user.tenant)                
              ]);
              setPartnersList(results[0]);            
      
          } catch (error) {
            console.error(error);            
          }
        };
        fetchData();
      }, []);


    const pageSettings: PageSettingsModel = { pageSize: 50 }
    const sortSettings: SortSettingsModel = { columns: [
                            {field: 'id', direction: 'Ascending' }
                        ] };
    // const filterSettings = { columns: [
    //         { field: 'id', operator: 'equal', value: 2 }
    //     ] };
    const filterOptions: FilterSettingsModel = {
        type: 'Menu'
      };
    const loadingIndicator: LoadingIndicatorModel = { indicatorType: 'Shimmer' };

    return (
        <>
        <GridComponent dataSource={partnersList} allowPaging={false} height="100%" 
                       pageSettings={pageSettings} allowSorting={true} sortSettings={sortSettings} 
                       allowFiltering={true} filterSettings={filterOptions}
                       loadingIndicator={loadingIndicator} >
            <ColumnsDirective>
                {/* <ColumnDirective field='id' width='100' textAlign="Right" maxWidth={20} headerText="ID" visible={false}/> */}
                <ColumnDirective field='partner_Name' width='200' maxWidth={200} headerText={t('documents.partnersList.partnerName')}/>
                <ColumnDirective field='id' width='100' textAlign="Right" maxWidth={20} headerText="ID" visible={false}/> 
                <ColumnDirective field='partner_Id' width='50' maxWidth={50} headerText={t('documents.partnersList.partnerId')}/>
                <ColumnDirective field='partner_Street' width='200' maxWidth={200} headerText={t('documents.partnersList.partnerStreet')}/>
                <ColumnDirective field='city_ZipCode' width='100' maxWidth={100} headerText={t('documents.partnersList.cityZipCode')}/>
                <ColumnDirective field='city_Name' width='150' maxWidth={150} headerText={t('documents.partnersList.cityName')}/>
                <ColumnDirective field='int_CountryName' width='150' maxWidth={150} headerText={t('documents.partnersList.countryName')}/>                
                <ColumnDirective field='partner_Phone' width='150' headerText={t('documents.partnersList.partnerPhone')}/>                
                <ColumnDirective field='partner_Email' width='150' maxWidth={150} headerText={t('documents.partnersList.partnerEmail')} />
                
                
            </ColumnsDirective>
            <Inject services={[Page, Sort, Filter, Group]}/>
        </GridComponent>
        </>
    )
}

export default PartnersList;