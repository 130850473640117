import React, { useState, useEffect, useRef } from "react";
import './salesDashboard.scss';
import { useTranslation } from "react-i18next";

import { getNumberOfInvoices, getSalesInPeriod, getNetSalesInPeriod, getSalesVatInPeriod, getTopCustomers, getTopCategories, getTopProducts,
         getOffersNumberInPeriod, getOffersTotalInPeriod, getOffersRealizedNumberInPeriod, getOffersRealizedTotalInPeriod, getSalesByMonth } from "../../../api/salesDashboardAPI";
import { DashboardBasicDto, DashboardDateRangeDto, DashboardChartDto } from '../../../Dto/BaseDto'

import SimpleCard from "../../../Components/Dashboards/SimpleCard/SimpleCard";
import ChartShell from "../../../Components/Dashboards/ChartShell/ChartShell";
import ToastMessage, { ToastMessageHandles } from "../../../Components/Dashboards/ToastMessage/ToastMessage";
// import PanelWrapper from "../../../Components/Dashboards/PanelWrapper/PanelWrapper";
import PageHeader from "../../../Components/PageHeader/PageHeader";
import { PageHeaderParent, SynAppValueSettingType } from "../../../Common/Enums";
import { useAppSelector, useAppDispatch } from '../../../Hooks/hooks';
import { truncateString, getPeriodTranslationString, formatShortDate } from '../../../Utility/Utility';
import { useTheme } from "../../../Contexts/ThemeContext";

import { Responsive, WidthProvider, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);


interface DashboardData {
  currentDateRangeData: DashboardDateRangeDto | null;
  numberOfInvoicesData: DashboardBasicDto | null;
  salesInPeriod: DashboardBasicDto | null;
  salesVatInPeriod: DashboardBasicDto | null;
  netSalesInPeriod: DashboardBasicDto | null;
  topCustomersData: DashboardChartDto[] | null; // Assuming an array of some sort, adjust as necessary
  topCategoriesData: DashboardChartDto[] | null;
  topProductsData: DashboardChartDto[] | null;
  offersNumberData: DashboardBasicDto | null;
  offersTotalData: DashboardBasicDto | null;
  offersRealizedNumberData: DashboardBasicDto | null;
  offersRealizedTotalData: DashboardBasicDto | null;
  salesByMonthData: DashboardChartDto[] | null; // Adjust according to the actual type
}

const SalesDashboard = () => {
  const theme = useTheme();
  const currThemeColor: string = (theme === 'dark' ? '#FFFFFF' : '#000000');
  const [t, i18n] = useTranslation();

  const [dashboardData, setDashboardData] = useState<DashboardData>({
    currentDateRangeData: null,
    numberOfInvoicesData: null,
    netSalesInPeriod: null,
    salesInPeriod: null,
    salesVatInPeriod: null,
    topCustomersData: null,
    topCategoriesData: null,
    topProductsData: null,
    offersNumberData: null,
    offersTotalData: null,
    offersRealizedNumberData: null,
    offersRealizedTotalData: null,
    salesByMonthData: null
  });   

  const toastRef = useRef<ToastMessageHandles>(null);

  const handleShowToast = () => {
    toastRef.current?.showToast();
  }

  const user = useAppSelector(state => state.user);

      const dateRangeSetting = useAppSelector(state => state.settings.settings[SynAppValueSettingType.SalesDashboardDateRange]);

      useEffect(() => {
        const fetchData = async () => {
          try {
            if (dateRangeSetting) {
              const data: DashboardDateRangeDto = JSON.parse(dateRangeSetting.settingValue);
              const fromDate = new Date(data.fromDate);
              const toDate = new Date(data.toDate);
              const formattedFromDate = fromDate.toISOString();
              const formattedToDate = toDate.toISOString();
      
              if (fromDate <= new Date("2022-12-31")) {
                handleShowToast();
              }
      
              const results = await Promise.all([
                getNumberOfInvoices(user.tenant, formattedFromDate, formattedToDate),
                getSalesInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getNetSalesInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getSalesVatInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getOffersNumberInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getOffersTotalInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getOffersRealizedNumberInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getOffersRealizedTotalInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getTopCustomers(user.tenant, formattedFromDate, formattedToDate),
                getTopCategories(user.tenant, formattedFromDate, formattedToDate),
                getTopProducts(user.tenant, formattedFromDate, formattedToDate),
                getSalesByMonth(user.tenant, formattedFromDate, formattedToDate)
              ]);
      
              setDashboardData(prevState => ({
                ...prevState,
                currentDateRangeData: data,
                numberOfInvoicesData: results[0],
                salesInPeriod: results[1],
                netSalesInPeriod: results[2],
                salesVatInPeriod: results[3],
                offersNumberData: results[4],
                offersTotalData: results[5],
                offersRealizedNumberData: results[6],
                offersRealizedTotalData: results[7],
                topCustomersData: results[8] || [],
                topCategoriesData: results[9] || [],
                topProductsData: results[10] || [],
                salesByMonthData: results[11] || []
              }));
            }
          } catch (error) {
            console.error(error);
            // Optionally, handle any UI updates related to error state here.
          }
        };
        fetchData();
      }, [dateRangeSetting]);
      
      
      // useEffect(() => {
      //   const fetchData = async () => {
      //     try {

      //       if (dateRangeSetting){
      //         const data: DashboardDateRangeDto = JSON.parse(dateRangeSetting.settingValue);    

      //         const fromDate = new Date(data.fromDate);
      //         const toDate = new Date(data.toDate);
      //         const formattedFromDate = fromDate.toISOString();
      //         const formattedToDate = toDate.toISOString();

      //         if (fromDate <= new Date("2022-12-31")) {
      //           handleShowToast();
      //         }

      //         //Number of invoices in period
      //         const numOfInvoicesRes = await getNumberOfInvoices(user.tenant, formattedFromDate, formattedToDate);              

      //         //Sales total in period including VAT
      //         const salesInPeriod = await getSalesInPeriod(user.tenant, formattedFromDate, formattedToDate);

      //         //Sales total in period excluding VAT
      //         const netSalesInPeriod = await getNetSalesInPeriod(user.tenant, formattedFromDate, formattedToDate);

      //         //VAT amount in period
      //         const salesVatInPeriod = await getSalesVatInPeriod(user.tenant, formattedFromDate, formattedToDate);

      //          //Offers number in period
      //          const offersNumber = await getOffersNumberInPeriod(user.tenant, formattedFromDate, formattedToDate);
 
      //          //Offers total in period
      //          const offersTotal = await getOffersTotalInPeriod(user.tenant, formattedFromDate, formattedToDate);
 
      //          //Offers total in period
      //          const offersRealizedNumber = await getOffersRealizedNumberInPeriod(user.tenant, formattedFromDate, formattedToDate);
 
      //          //Offers realized number in period
      //          const offersRealizedTotal = await getOffersRealizedTotalInPeriod(user.tenant, formattedFromDate, formattedToDate);

      //         //Top customers
      //         const topCustomers = await getTopCustomers(user.tenant, formattedFromDate, formattedToDate) || [];

      //         //Top categories
      //         const topCategories = await getTopCategories(user.tenant, formattedFromDate, formattedToDate) || [];

      //         //Top products
      //         const topProducts = await getTopProducts(user.tenant, formattedFromDate, formattedToDate) || [];

      //         //Sales by month
      //         const salesByMonth = await getSalesByMonth(user.tenant, formattedFromDate, formattedToDate) || [];

      //         setDashboardData(prevState => ({
      //           ...prevState,
      //           currentDateRangeData: data,
      //           numberOfInvoicesData: numOfInvoicesRes,
      //           salesInPeriod: salesInPeriod,
      //           netSalesInPeriod: netSalesInPeriod,
      //           salesVatInPeriod: salesVatInPeriod,
      //           offersNumberData: offersNumber,
      //           offersTotalData: offersTotal,
      //           offersRealizedNumberData: offersRealizedNumber,
      //           offersRealizedTotalData: offersRealizedTotal,
      //           topCustomersData: topCustomers,
      //           topCategoriesData: topCategories,
      //           topProductsData: topProducts,
      //           salesByMonthData: salesByMonth
      //         }));
      //       }
      //     }
      //     catch (error) {
      //       console.error(error);
      //   }
      //   }; 
      //   fetchData();
      // }, [dateRangeSetting]);

      //Gets the period name or date range
      function getPeriodName() {
        let name: string = '';

        if (dashboardData.currentDateRangeData && dashboardData.currentDateRangeData.rangeType === '11'){  //11 is a custom range of dates
          const from: string = formatShortDate(new Date(dashboardData.currentDateRangeData.fromDate));
          const to: string = formatShortDate(new Date(dashboardData.currentDateRangeData.toDate));
          name = from + ' - ' + to;
        }
        else if (dashboardData.currentDateRangeData) {          
          name = t(getPeriodTranslationString(parseInt(dashboardData.currentDateRangeData.rangeType)));
        }

        return name;
      }

      function salesByMonthPanel() {
        const seriesData = [{dataSource: dashboardData.salesByMonthData || [], xName: 'description', yName: 'amount', type: 'Column', name: '', pointColorMapping: 'color', 
                             marker: {visible: true, dataLabel: { visible: true, font: { fontWeight: '500', color: `${currThemeColor}` }, labelFormat: 'C2' }, 
                             height: 10, width: 10,} }];
        const xAxis = { valueType: 'Category', majorGridLines: { width: 0 }};
        const yAxis = { labelFormat: 'C2'};
        const props = { title: i18n.t('dashboards.sales.salesByMonth').toUpperCase(), titleStyle: {size: '30px', fontWeight: 'bold'}, locale: 'hr' };

        return (
          <ChartShell chartProps={props} seriesData={seriesData} primaryXAxis={xAxis} primaryYAxis={yAxis} />
        )
      }

      const layout: Layout[] = [
        //1. ROW
        {i: 'numberOfInvoicesPanel', x: 0, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel1', x: 2, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel2', x: 4, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel3', x: 6, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},

        //2. ROW
        {i: 'salesPanel4', x: 0, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel5', x: 2, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel6', x: 4, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel7', x: 6, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},

        //3.ROW
        {i: 'salesPanel8', x: 0, y: 2, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false},
        {i: 'salesPanel9', x: 4, y: 2, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false},
        {i: 'topProductsPanel', x: 0, y: 6, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false},
        {i: 'salesByMonthPanel', x: 4, y: 6, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false}
      ];

    return (
          <div className="control-section">
            <div className="dashboard-wrapper">
            <div className="dashboard-header">
            <PageHeader title={t("dashboards.sales.dashboardTitle")} 
                        headerParent={PageHeaderParent.SalesDashboard}
                        displayRange={true} 
                        displaySettingsButton={true} 
                        settingsButtonTarget={''} />
            </div>
            <div className="dashboard-container">
            <ResponsiveGridLayout className="layout" layouts={{lg: layout}} breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }} cols={{ lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 }} rowHeight={30}>
              <div key="numberOfInvoicesPanel">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.numberOfInvoicesData ? dashboardData.numberOfInvoicesData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.sales.numberOfInvoices")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel1">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.salesInPeriod ? dashboardData.salesInPeriod.amount : 0}
                  currencySymbol={'€'}
                  header={t("dashboards.sales.salesInPeriod")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel2">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.netSalesInPeriod ? dashboardData.netSalesInPeriod.amount : 0}
                  currencySymbol={'€'}
                  header={t("dashboards.sales.netSalesInPeriod")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel3">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.salesVatInPeriod ? dashboardData.salesVatInPeriod.amount : 0}
                  currencySymbol={'€'}
                  header={t("dashboards.sales.salesVatInPeriod")}
                  footer={getPeriodName()}
                />}
              </div>

              <div key="salesPanel4">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.offersNumberData ? dashboardData.offersNumberData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.sales.offersNumber")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel5">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.offersTotalData ? dashboardData.offersTotalData.amount : 0}
                  currencySymbol={'€'}
                  header={t("dashboards.sales.offersTotal")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel6">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.offersRealizedNumberData ? dashboardData.offersRealizedNumberData.amount : 0}
                  currencySymbol={'€'}
                  header={t("dashboards.sales.offersRealizedNumber")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel7">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.offersRealizedTotalData ? dashboardData.offersRealizedTotalData.amount : 0}
                  currencySymbol={'€'}
                  header={t("dashboards.sales.offersRealizedTotal")}
                  footer={getPeriodName()}
                />}
              </div>

              <div key="salesPanel8">
                <ChartShellPanelComponent inputData={dashboardData.topCustomersData || []} title={i18n.t('dashboards.sales.topCustomers').toUpperCase()} />
              </div>

              <div key="salesPanel9">
                <ChartShellPanelComponent inputData={dashboardData.topCategoriesData || []} title={i18n.t('dashboards.sales.topCategories').toUpperCase()} />
              </div>
              <div key="topProductsPanel">
                <ChartShellPanelComponent inputData={dashboardData.topCategoriesData || []} title={i18n.t('dashboards.sales.topCategories').toUpperCase()} />
              </div>
              <div key="salesByMonthPanel">
                {salesByMonthPanel()}
              </div>
            </ResponsiveGridLayout>
            
            </div>
        </div>

        <ToastMessage
                ref={toastRef}
                title={t("general.yearBeforeEuroNotificationTitle")}
                message={t("general.yearBeforeEuroNotification")}
                timeOut={3000}
                height={120}
            />
      </div>
    )
}

export default SalesDashboard;

interface SimpleCardPanelProps {
  inputNumber: number;
  currencySymbol: string;
  header: string;
  footer: string;
}

const SimpleCardPanelComponent: React.FC<SimpleCardPanelProps> = React.memo(({ inputNumber, currencySymbol, header, footer }) => {
  return (
    <SimpleCard 
      inputNumber={inputNumber} 
      isDecimal={true} 
      currencySymbol={currencySymbol} 
      header={header} 
      footer={footer} />
  );
});


interface ChartShellComponentProps {
  inputData: DashboardChartDto[]; 
  title: string;
}

const ChartShellPanelComponent: React.FC<ChartShellComponentProps> = React.memo(({ inputData, title }) => {
  const seriesData = [{ dataSource: inputData, xName: 'description', yName: 'amount', type: 'Bar', name: '', pointColorMapping: 'color' }];
  const xAxis = { valueType: 'Category', majorGridLines: { width: 0 } };
  const yAxis = { labelFormat: '{value}' };
  const chartProps = { 
    title: title, 
    titleStyle: { size: '30px', fontWeight: 'bold' } 
  };

  return (<ChartShell chartProps={chartProps} seriesData={seriesData} primaryXAxis={xAxis} primaryYAxis={yAxis} />);
});
