import React from 'react';
import './offersListParent.scss';
import { useTranslation } from "react-i18next";

import PageHeader from "../../../Components/PageWrapper/PageWrapper";
import PageWrapper from '../../../Components/PageWrapper/PageWrapper';
import { PageHeaderParent } from "../../../Common/Enums";
import OffersList from '../../../Pages/Documents/Offers/OffersList';

const OffersListParent: React.FC = () => {
    const [t, i18n] = useTranslation();


  return (
    <PageWrapper title={t("documents.offersList.title")} 
                 pageHeaderParent={PageHeaderParent.OffersList}
                 displayRange={true} 
                 displaySettingsButton={true} 
                 settingsButtonTarget={''} >
      <OffersList />
    </PageWrapper>
  );
};

export default OffersListParent;