import { useTranslation } from "react-i18next";

// const { t } = useTranslation();

const menuTreeItems: Object[] = [
  {
    "nodeId": "p1", "nodeText": "Nadzorne ploče", "expanded": false, "iconCss": "e-icons e-medium e-chart", "nodeChild": [
      { "nodeId": "/sales-dashboard", "nodeText": "Prodaja", "icon": "audio", "cssClass": "e-list-icon" },
        { "nodeId": "/retail-dashboard", "nodeText": "Maloprodaja", "icon": "audio", "cssClass": "e-list-icon" },
        { "nodeId": "/test", "nodeText": "Financije", "icon": "audio", "cssClass": "e-list-icon" },
        { "nodeId": "/test2", "nodeText": "Skladište robe", "icon": "audio", "cssClass": "e-list-icon" }
      ]
    },
    {
      "nodeId": "p2", "nodeText": "Dokumenti", "icon": "folder", "expanded": false, "iconCss": "e-icons e-medium e-table", "nodeChild": [
        { "nodeId": "/offers-list", "nodeText": "Ponude", "icon": "video" },
        { "nodeId": "/invoices-list", "nodeText": "Računi", "icon": "video" },
        { "nodeId": "/partners-list", "nodeText": "Partneri", "icon": "video" },
        { "nodeId": "02-02", "nodeText": "Roba/Usluge", "icon": "video" }
      ]
    },
    {
      "nodeId": "p3", "nodeText": "Izvještaji", "icon": "folder", "expanded": false, "iconCss": "e-icons e-medium e-table", "nodeChild": [
        { "nodeId": "02-01", "nodeText": "Ponude", "icon": "video" },
        { "nodeId": "02-02", "nodeText": "Računi", "icon": "video" },
      ]
    },
    {
      "nodeId": "p4", "nodeText": "Planiranje", "icon": "folder", "expanded": false, "iconCss": "e-icons e-medium e-table", "nodeChild": [
        { "nodeId": "02-01", "nodeText": "Poslovni plan", "icon": "video" }
      ]
    }
]

const dashboards1: { [key: string]: Object }[] = [
    { text: 'Account Settings', iconCss: 'e-list-icons e-list-user-settings' },
    { text: 'Address Book', iconCss: 'e-list-icons e-list-address-book' },
    { text: 'Delete', iconCss: 'e-list-icons e-list-delete' },
    { text: 'Forward', iconCss: 'e-list-icons e-list-forward' },
    { text: 'Reply', iconCss: 'e-list-icons e-list-reply' },
    { text: 'Reply All', iconCss: 'e-list-icons e-list-reply-all' },
    { text: 'Save All Attachments', iconCss: 'e-list-icons e-list-save-all-attachments' },
    { text: 'Save As', iconCss: 'e-list-icons e-list-icon-save-as' },
    { text: 'Touch/Mouse Mode', iconCss: 'e-list-icons e-list-touch' },
    { text: 'Undo', iconCss: ' e-list-icons e-list-undo' }
  ];

  const dashboards: { [key: string]: Object }[] = [
    { text: 'Prodaja', id: 'list-01' },
    { text: 'Maloprodaja', id: 'list-02' },
    { text: 'Financije', id: 'list-03' },
    { text: 'Ponude', id: 'list-04' },
    { text: 'Računi', id: 'list-05' }
]

export default dashboards;
export { dashboards1 };
export { menuTreeItems };