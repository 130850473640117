import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./content.scss";
import "../../assets/themeStyles.scss";
import { Outlet } from 'react-router-dom';

import { useTheme } from "../../Contexts/ThemeContext";

interface ContentProps {
  isSidebarOpen: boolean;
}

const Content: React.FC<ContentProps> = ({ isSidebarOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={`content-wrapper flex-1 overflow-y-auto p-4 ${isSidebarOpen ? "ml-60" : "w-full"} ${theme === 'dark' ? "content-dark" : "content-light"}`}>
      <div className="border-0 border-gray-400 h-full w-full">
          <Outlet />
      </div>
    </div>
  );
};

export default Content;
