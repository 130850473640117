import React from 'react';
import './simpleCard.scss';
import "../../../assets/themeStyles.scss";
import { useTheme } from "../../../Contexts/ThemeContext";

interface SimpleCardProps {
    header?: string;
    footer?: string;
    inputNumber: number;
    currencySymbol?: string;
    isDecimal?: boolean;
    percentage?: number;
    backgroundLightColor?: string;
    backgroundDarkColor?: string;
}

const SimpleCard: React.FC<SimpleCardProps> = ({header, footer, inputNumber, currencySymbol, isDecimal, percentage, backgroundLightColor, backgroundDarkColor}) => {
    const theme = useTheme();
    // const formattedNumber = isDecimal ? inputNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : inputNumber;
    const formattedNumber = isDecimal ? new Intl.NumberFormat().format(inputNumber) : inputNumber;

    // const displayNumber = new Intl.NumberFormat().format(formattedNumber);

    let percentClass = "percentage";
    let percentNumber: string = '';

    if (percentage != null){
        if (percentage < 0){
            percentClass = "percentage negative";
            percentNumber = '(' + percentage + '%)';
        }
        else {
            percentClass = "percentage positive";
            percentNumber = '(+' + percentage + '%)';
        }
    }

    return (
    <div className={`card-wrapper ${theme === 'dark' ? "simple-card-dark simple-card-background-dark" : "simple-card-light simple-card-background-light"}`} 
                    style={{ width: '100%', height: '100%', backgroundColor: `${theme === 'dark' ? backgroundDarkColor : backgroundLightColor}` }}>
        <div className="dashboard-card">
        <div id="title" className={`title`}>
            {header}
        </div>
        <div id="mainNumber" className="revenue">
            <span className='currency-symbol'>{currencySymbol}</span> {formattedNumber} <span className={percentClass}>{percentNumber}</span>
        </div>
        {footer !== '' && (
            <div id="footerText" className="bottom-text">
                {footer}
            </div>
        )}
    </div>
    </div>
    )
}

export default SimpleCard;