import React, { useState, useEffect, useRef } from "react";
import './retailDashboard.scss';
import { useTranslation } from "react-i18next";

import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { getStoresList, getSalesInPeriod, getNumberOfInvoicesInPeriod, getNetSalesInPeriod, getSalesVatInPeriod, getTopCategories,
         getTopProducts, getTopInvoiceAmounts, getMarginTotal, getDiscountTotal, getAverageDiscountPercent, getAverageDiscountAmount,
         getSalesByMonthByStore, getSalesByMonthAllStores, getNumberOfItemsSoldOnDiscount } from "../../../api/retailDashboardAPI";
import { DashboardBasicDto, DashboardDateRangeDto, DashboardChartDto, KeyValueDto } from '../../../Dto/BaseDto'

import SimpleCard from "../../../Components/Dashboards/SimpleCard/SimpleCard";
import SimpleEmptyCard from "../../../Components/Dashboards/SimpleEmptyCard/SimpleEmptyCard";
import ChartShell from "../../../Components/Dashboards/ChartShell/ChartShell";
import ToastMessage, { ToastMessageHandles } from "../../../Components/Dashboards/ToastMessage/ToastMessage";
// import PanelWrapper from "../../../Components/Dashboards/PanelWrapper/PanelWrapper";
import PageHeader from "../../../Components/PageHeader/PageHeader";
import { PageHeaderParent, SynAppValueSettingType } from "../../../Common/Enums";
import { updateValueSetting, getValueSetting, SynAppValueSetting } from "../../../api/synSettingsAPI";
import { useAppSelector, useAppDispatch } from '../../../Hooks/hooks';
import { setSetting } from "../../../state/settings/settingsSlice";
import { truncateString, getPeriodTranslationString, formatShortDate } from '../../../Utility/Utility';
import { useTheme } from "../../../Contexts/ThemeContext";

import { Responsive, WidthProvider, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface DashboardData {
  storesListData: KeyValueDto[] | null;
  currentDateRangeData: DashboardDateRangeDto | null;
  salesInPeriodData: DashboardBasicDto | null;
  numberOfInvoicesInPeriodData: DashboardBasicDto | null;
  netSalesInPeriodData: DashboardBasicDto | null;
  salesVatInPeriodData: DashboardBasicDto | null;
  topCategoriesData: DashboardChartDto[] | null; 
  topProductsData: DashboardChartDto[] | null;
  topInvoiceAmountsData: DashboardChartDto[] | null;
  marginTotalData: DashboardBasicDto | null;
  discountTotalData: DashboardBasicDto | null;
  averageDiscountPercentData: DashboardBasicDto | null;
  averageDiscountAmountData: DashboardBasicDto | null;
  salesByMonthByStoreData: DashboardChartDto[] | null; 
  salesByMonthAllStoresData: DashboardChartDto[] | null;
  numberOfItemsSoldOnDiscountData: DashboardBasicDto | null;
}

const SalesDashboard = () => {
      const theme = useTheme();
      const currThemeColor: string = (theme === 'dark' ? '#FFFFFF' : '#000000');
      const [t, i18n] = useTranslation();

      // const [storeList, setStoreList] = useState<KeyValueDto[]>([]);
      const [selectedStoreList, setSelectedStoreList] = useState<string>('');

      const [dashboardData, setDashboardData] = useState<DashboardData>({
        storesListData: null,
        currentDateRangeData: null,
        salesInPeriodData: null,
        numberOfInvoicesInPeriodData: null,
        netSalesInPeriodData: null,
        salesVatInPeriodData: null,
        topCategoriesData: null,
        topProductsData: null,
        topInvoiceAmountsData: null,
        marginTotalData: null,
        discountTotalData: null,
        averageDiscountPercentData: null,
        averageDiscountAmountData: null,
        salesByMonthByStoreData: null,
        salesByMonthAllStoresData: null,
        numberOfItemsSoldOnDiscountData: null
      });    

      // const [currentDateRangeData, setCurrentDateRangeData] = useState<DashboardDateRangeDto>();

      const toastRef = useRef<ToastMessageHandles>(null);

      const handleShowToast = () => {
        toastRef.current?.showToast();
      }

      const user = useAppSelector(state => state.user);
      const dispatch = useAppDispatch();   

      //Get the selected stores from settings store
      const selectedStores = useAppSelector(state => state.settings.settings[SynAppValueSettingType.SelectedRetailStores]);

    //   useEffect(() => {
    //     // Perform a side effect using `dispatch` here.
    //     // For example, dispatch an action if `selectedStores` changes.
        
    //     if (selectedStores) {
    //       // console.log("BABABABA:" + selectedStores.settingValue)
    //         // Example side effect: dispatch an action based on selectedStores
    //         // dispatch(someAction(selectedStores));
    //         // setSelectedStoreList(selectedStores.settingValue);
    //     }
    // }, [selectedStores, dispatch]); // Dependencies array ensures effect runs only when `selectedStores` changes.
    
      
      // console.log("H1: "+selectedStores.settingValue);
      
      const dateRangeSetting = useAppSelector(state => state.settings.settings[SynAppValueSettingType.RetailDashboardDateRange]);

      useEffect(() => {
        const fetchData = async () => {
          try {
            if (dateRangeSetting) {
              const data: DashboardDateRangeDto = JSON.parse(dateRangeSetting.settingValue);
              // setCurrentDateRangeData(data);
      
              const fromDate = new Date(data.fromDate);
              const toDate = new Date(data.toDate);
              const formattedFromDate = fromDate.toISOString();
              const formattedToDate = toDate.toISOString();
      
              //Before EUR in HR
              if (fromDate <= new Date("2022-12-31")) {
                handleShowToast();
              }
      
              const storesList = await getStoresList(user.tenant);
              // setStoreList(storesList);
      
              const stores = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
      
              const [
                salesInPeriodData,
                numberOfInvoicesInPeriodData,
                netSalesInPeriodData,
                salesVatInPeriodData,
                topCategoriesData,
                topProductsData,
                topInvoiceAmountsData,
                // marginTotalData,
                discountTotalData,
                averageDiscountPercentData,
                averageDiscountAmountData,
                salesByMonthByStoreData,
                salesByMonthAllStoresData,
                // numberOfItemsSoldOnDiscountData
              ] = await Promise.all([
                getSalesInPeriod(user.tenant, formattedFromDate, formattedToDate, stores),
                getNumberOfInvoicesInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getNetSalesInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getSalesVatInPeriod(user.tenant, formattedFromDate, formattedToDate),
                getTopCategories(user.tenant, formattedFromDate, formattedToDate),
                getTopProducts(user.tenant, formattedFromDate, formattedToDate),
                getTopInvoiceAmounts(user.tenant, formattedFromDate, formattedToDate),
                // getMarginTotal(user.tenant, formattedFromDate, formattedToDate),
                getDiscountTotal(user.tenant, formattedFromDate, formattedToDate),
                getAverageDiscountPercent(user.tenant, formattedFromDate, formattedToDate),
                getAverageDiscountAmount(user.tenant, formattedFromDate, formattedToDate),
                getSalesByMonthByStore(user.tenant, formattedFromDate, formattedToDate),
                getSalesByMonthAllStores(user.tenant, formattedFromDate, formattedToDate),
                // getNumberOfItemsSoldOnDiscount(user.tenant, formattedFromDate, formattedToDate)
              ]);
      
              setDashboardData(prevState => ({
                ...prevState,
                storesListData: storesList,
                currentDateRangeData: data,
                salesInPeriodData,
                numberOfInvoicesInPeriodData,
                netSalesInPeriodData,
                salesVatInPeriodData,
                topCategoriesData,
                topProductsData,
                topInvoiceAmountsData,
                // marginTotalData,
                discountTotalData,
                averageDiscountPercentData,
                averageDiscountAmountData,
                salesByMonthByStoreData,
                salesByMonthAllStoresData,
                // numberOfItemsSoldOnDiscountData
              }));
            }
          } catch (error) {
            console.error(error);
            // Optionally, handle any UI updates related to error state here.
          }
        };
        fetchData();
      }, [dateRangeSetting]);
      


      // useEffect(() => {
      //   const fetchData = async () => {
      //     try {

      //       if (dateRangeSetting){
      //         const data: DashboardDateRangeDto = JSON.parse(dateRangeSetting.settingValue);    
      //         // setCurrentDateRangeData(data);

      //         const fromDate = new Date(data.fromDate);
      //         const toDate = new Date(data.toDate);
      //         const formattedFromDate = fromDate.toISOString();
      //         const formattedToDate = toDate.toISOString();

      //         //Before EUR in HR
      //         if (fromDate <= new Date("2022-12-31")) {
      //           handleShowToast();
      //         }

      //         //Number of invoices in period
      //         const storesList = await getStoresList(user.tenant);
      //         // setStoreList(storesList);              

      //         const stores = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

      //         //Sales total in period including VAT
      //         const salesInPeriodData = await getSalesInPeriod(user.tenant, formattedFromDate, formattedToDate, stores);

      //         //Sales total in period excluding VAT
      //         const numberOfInvoicesInPeriodData = await getNumberOfInvoicesInPeriod(user.tenant, formattedFromDate, formattedToDate);

      //         const netSalesInPeriodData = await getNetSalesInPeriod(user.tenant, formattedFromDate, formattedToDate);

      //         //Sales total in period excluding VAT
      //         const salesVatInPeriodData = await getSalesVatInPeriod(user.tenant, formattedFromDate, formattedToDate);

      //          //Offers number in period
      //          const topCategoriesData = await getTopCategories(user.tenant, formattedFromDate, formattedToDate);
 
      //          //Offers total in period
      //          const topProductsData = await getTopProducts(user.tenant, formattedFromDate, formattedToDate);
 
      //          //Offers total in period
      //          const topInvoiceAmountsData = await getTopInvoiceAmounts(user.tenant, formattedFromDate, formattedToDate);
 
      //          //Offers realized number in period
      //         //  const marginTotalData = await getMarginTotal(user.tenant, formattedFromDate, formattedToDate);
      //         //  setOffersRealizedTotalData(offersRealizedTotal);

      //         //Top customers
      //         const discountTotalData = await getDiscountTotal(user.tenant, formattedFromDate, formattedToDate);

      //         //Top categories
      //         const averageDiscountPercentData = await getAverageDiscountPercent(user.tenant, formattedFromDate, formattedToDate);

      //         //Top products
      //         const averageDiscountAmountData = await getAverageDiscountAmount(user.tenant, formattedFromDate, formattedToDate);

      //         const salesByMonthByStoreData = await getSalesByMonthByStore(user.tenant, formattedFromDate, formattedToDate);

      //         //Sales by month
      //         const salesByMonthAllStoresData = await getSalesByMonthAllStores(user.tenant, formattedFromDate, formattedToDate);

      //         // const numberOfItemsSoldOnDiscountData = await getNumberOfItemsSoldOnDiscount(user.tenant, formattedFromDate, formattedToDate);

      //         setDashboardData(prevState => ({
      //           ...prevState,
      //           storesListData: storesList,
      //           currentDateRangeData: data,
      //           salesInPeriodData: salesInPeriodData,
      //           numberOfInvoicesInPeriodData: numberOfInvoicesInPeriodData,
      //           netSalesInPeriodData: netSalesInPeriodData,
      //           salesVatInPeriodData: salesVatInPeriodData,
      //           topCategoriesData: topCategoriesData,
      //           topProductsData: topProductsData,
      //           topInvoiceAmountsData: topInvoiceAmountsData,
      //           // marginTotalData: marginTotalData,
      //           discountTotalData: discountTotalData,
      //           averageDiscountPercentData: averageDiscountPercentData,
      //           averageDiscountAmountData: averageDiscountAmountData,
      //           salesByMonthByStoreData: salesByMonthByStoreData,
      //           salesByMonthAllStoresData: salesByMonthAllStoresData,
      //           // numberOfItemsSoldOnDiscountData: numberOfItemsSoldOnDiscountData
      //         }));
      //       }
      //     }
      //     catch (error) {
      //       console.error(error);
      //   }
      //   }; 
      //   fetchData();
      // }, [dateRangeSetting]);

      //Gets the period name or date range
      function getPeriodName() {
        let name: string = '';

        if (dashboardData.currentDateRangeData && dashboardData.currentDateRangeData.rangeType === '11'){
          const from: string = formatShortDate(new Date(dashboardData.currentDateRangeData.fromDate));
          const to: string = formatShortDate(new Date(dashboardData.currentDateRangeData.toDate));
          name = from + ' - ' + to;
        }
        else if (dashboardData.currentDateRangeData) {          
          name = t(getPeriodTranslationString(parseInt(dashboardData.currentDateRangeData.rangeType)));
        }

        return name;
      }

      // function handleStoresSelectionChange(selectedItems: any) {
      //   const settingToUpdate: SynAppValueSetting = {
      //     tenantId: user.tenantId,
      //     userId: user.id,
      //     settingType: SynAppValueSettingType.SelectedRetailStores,
      //     settingValue: JSON.stringify(selectedItems),
      //   }
      //   console.log("H4: "+selectedItems);
      //   updateValueSetting(user.tenant, settingToUpdate);
      //   dispatch(setSetting(settingToUpdate));
      // }


      const handleStoresSelectionChange = React.useCallback((selectedItems: any) => {
          const settingToUpdate: SynAppValueSetting = {
          tenantId: user.tenantId,
          userId: user.id,
          settingType: SynAppValueSettingType.SelectedRetailStores,
          settingValue: JSON.stringify(selectedItems),
        }
        updateValueSetting(user.tenant, settingToUpdate);
        dispatch(setSetting(settingToUpdate));
        setSelectedStoreList(JSON.stringify(selectedItems));
        // console.log("KKK:" +selectedStoreList);
      }, [selectedStoreList])

      function StoresListPanel(onSelectionChange: any, selectedItems: string) {
        // let stores: string[];
        // stores = JSON.parse(selectedItems);
        // console.log("H3:" +stores)

        // let stores = [];
        // try {
        //   stores = JSON.parse(selectedItems);
        // } catch (e) {
        //   console.error("Parsing error: ", e);
        //   stores = []; // Set a default value or handle error as needed
        // }

        const selected=  ["1", "2", "3"];        

        return (
          <SimpleEmptyCard backgroundDarkColor="#424141">
                    <MultiSelectComponent id="checkbox" 
                                          dataSource={dashboardData.storesListData as unknown as { [key: string]: Object; }[]}
                                          // dataSource={storeList}
                                          fields={{ text: 'value', value: 'key' }} 
                                          placeholder={t("dashboards.retail.selectStores")} 
                                          mode="CheckBox" 
                                          showSelectAll={true} showDropDownIcon={true}
                                          filterBarPlaceholder={t("dashboards.retail.searchStores")} 
                                          value={selected}
                                          change={(e) => onSelectionChange(e.value)}                                          
                                          popupHeight="350px">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                    <ButtonComponent cssClass='e-flat e-primary'>{t("dashboards.retail.refreshData")}</ButtonComponent>
          </SimpleEmptyCard>
        )
      }

      const layout: Layout[] = [
        //1. ROW
        {i: 'numberOfInvoicesPanel', x: 0, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel1', x: 2, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel2', x: 4, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel3', x: 6, y: 0, w: 2, h: 4, minW: 2, maxW: 2, static: false},

        //2. ROW
        {i: 'salesPanel4', x: 0, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel5', x: 2, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel6', x: 4, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},
        {i: 'salesPanel7', x: 6, y: 1, w: 2, h: 4, minW: 2, maxW: 2, static: false},

        //3.ROW
        {i: 'salesPanel8', x: 0, y: 2, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false},
        {i: 'salesPanel9', x: 4, y: 2, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false},
        {i: 'topProductsPanel', x: 0, y: 6, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false},
        {i: 'salesByMonthPanel', x: 4, y: 6, w: 4, h: 8, minW: 2, maxW: 4, static: false, isResizable: false}
      ];

    return (
          <div className="control-section">
            <div className="dashboard-wrapper">
            <div className="dashboard-header">
            <PageHeader title={t("dashboards.sales.dashboardTitle")} 
                        headerParent={PageHeaderParent.RetailDashboard}
                        displayRange={true} 
                        displaySettingsButton={true} 
                        settingsButtonTarget={''} />
            </div>
            <div className="dashboard-container">
            <ResponsiveGridLayout className="layout" layouts={{lg: layout}} breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }} cols={{ lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 }} rowHeight={30}>
              
              <div key="numberOfInvoicesPanel">
                {StoresListPanel(handleStoresSelectionChange, "['8','1']")} 
                {/* {StoresListPanel(handleStoresSelectionChange, selectedStores.settingValue)}  */}
                {/* <StoresListPanel 
                  onSelectionChange={handleStoresSelectionChange} 
                  selectedItems="" // Assuming selectedStoreIds holds the currently selected store IDs
                  storeList={storeList}
                  t={t} // Assuming `t` is from useTranslation hook or similar
                /> */}

              </div>
              <div key="salesPanel1">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.salesInPeriodData ? dashboardData.salesInPeriodData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.retail.salesInPeriod")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel2">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.numberOfInvoicesInPeriodData ? dashboardData.numberOfInvoicesInPeriodData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.retail.numberOfInvoicesInPeriod")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel3">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.netSalesInPeriodData ? dashboardData.netSalesInPeriodData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.retail.netSalesInPeriod")}
                  footer={getPeriodName()}
                />}
              </div>

              <div key="salesPanel4">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.averageDiscountAmountData ? dashboardData.averageDiscountAmountData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.retail.averageDiscountAmount")}
                  footer={getPeriodName()}
                />}
              </div>

              <div key="salesPanel5">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.salesVatInPeriodData ? dashboardData.salesVatInPeriodData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.retail.salesVatInPeriod")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel6">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.averageDiscountPercentData ? dashboardData.averageDiscountPercentData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.retail.averageDiscountPercent")}
                  footer={getPeriodName()}
                />}
              </div>
              <div key="salesPanel7">
                {<SimpleCardPanelComponent 
                  inputNumber={dashboardData.averageDiscountAmountData ? dashboardData.averageDiscountAmountData.amount : 0}
                  currencySymbol={''}
                  header={t("dashboards.retail.averageDiscountAmount")}
                  footer={getPeriodName()}
                />}
              </div>

              <div key="salesPanel8">
                <ChartShellPanelComponent inputData={dashboardData.topCategoriesData || []} title={i18n.t('dashboards.retail.topCategories').toUpperCase()} />
              </div>
              <div key="salesPanel9">
                <ChartShellPanelComponent inputData={dashboardData.topProductsData || []} title={i18n.t('dashboards.retail.topProducts').toUpperCase()} />
              </div>
              <div key="topProductsPanel">
                <ChartShellPanelComponent inputData={dashboardData.topInvoiceAmountsData || []} title={i18n.t('dashboards.retail.topInvoiceAmounts').toUpperCase()} />
              </div>
              <div key="salesByMonthPanel">
                <ChartShellPanelComponent inputData={dashboardData.salesByMonthByStoreData || []} title={i18n.t('dashboards.retail.salesByMonthByStore').toUpperCase()} />
              </div>
            </ResponsiveGridLayout>
            
            </div>
        </div>

        <ToastMessage
                ref={toastRef}
                title={t("general.yearBeforeEuroNotificationTitle")}
                message={t("general.yearBeforeEuroNotification")}
                timeOut={3000}
                height={120}
            />
      </div>
    )
}

export default SalesDashboard;

interface SimpleCardPanelProps {
  inputNumber: number;
  currencySymbol: string;
  header: string;
  footer: string;
}

const SimpleCardPanelComponent: React.FC<SimpleCardPanelProps> = React.memo(({ inputNumber, currencySymbol, header, footer }) => {
  return (
    <SimpleCard 
      inputNumber={inputNumber} 
      isDecimal={true} 
      currencySymbol={currencySymbol} 
      header={header} 
      footer={footer} />
  );
});


interface ChartShellComponentProps {
  inputData: DashboardChartDto[]; 
  title: string;
}

const ChartShellPanelComponent: React.FC<ChartShellComponentProps> = React.memo(({ inputData, title }) => {
  const seriesData = [{ dataSource: inputData, xName: 'description', yName: 'amount', type: 'Bar', name: '', pointColorMapping: 'color' }];
  const xAxis = { valueType: 'Category', majorGridLines: { width: 0 } };
  const yAxis = { labelFormat: '{value}' };
  const chartProps = { 
    title: title, 
    titleStyle: { size: '30px', fontWeight: 'bold' } 
  };

  return (<ChartShell chartProps={chartProps} seriesData={seriesData} primaryXAxis={xAxis} primaryYAxis={yAxis} />);
});



interface StoresListPanelProps {
  onSelectionChange: (selectedItems: any) => void; // Adjust the type according to what `onSelectionChange` expects
  selectedItems: string; // Assuming this is a JSON string representation of the selected items
  storeList: KeyValueDto[]; // Adjust the type according to your actual store list structure
  t: (key: string) => string; // Function for localization
}

const StoresListPanel1: React.FC<StoresListPanelProps> = React.memo(({ onSelectionChange, selectedItems, storeList, t }) => {
console.log("TATATA: "+storeList);
  const stores = ['8'];
  // try {
  //   stores = JSON.parse(selectedItems);
  // } catch (e) {
  //   console.error("Parsing error: ", e);
  //   stores = []; // Set a default value or handle errors as needed
  // }

  // console.log("BAAA:"+selectedItems);

  // const multiSelectRef = useRef<any>(null); // Step 1: Create a ref

  // useEffect(() => {
  //   if (multiSelectRef.current) {
  //     let valuesToSelect;
  //     try {
  //       valuesToSelect = JSON.parse(selectedItems);
  //     } catch (error) {
  //       console.error("Error parsing selectedItems:", error);
  //       valuesToSelect = [];
  //     }
  
  //     // Hypothetical method to set selected items, replace with the actual method
  //     multiSelectRef.current.selectValues(valuesToSelect);
  //   }
  // }, [selectedItems, storeList]);
  

  // useEffect(() => {
  //   // Assuming `selectedItems` is already in the correct format (e.g., an array of keys)
  //   // and `storeList` is loaded
  //   if (multiSelectRef.current) {
  //     let valuesToSelect;
  //     try {
  //       valuesToSelect = JSON.parse(selectedItems);
  //     } catch (error) {
  //       console.error("Error parsing selectedItems:", error);
  //       valuesToSelect = [];
  //     }

  //     // Step 3: Select values programmatically
  //     // Replace `value` with the appropriate property if needed
  //     multiSelectRef.current.value = valuesToSelect;
  //     multiSelectRef.current.dataBind(); // Important: bind data to reflect changes
  //   }
  // }, [selectedItems, storeList]);

  // Note: The hardcoded `selected` array from your original code is replaced
  // by `stores` which is derived from `selectedItems`, ensuring dynamic selection

  return (
    <SimpleEmptyCard backgroundDarkColor="#424141">
      <MultiSelectComponent
      // ref={multiSelectRef}
        id="checkbox"
        dataSource={storeList as unknown as { [key: string]: Object; }[]}
        fields={{ text: 'value', value: 'key' }}
        placeholder={t("dashboards.retail.selectStores")}
        mode="CheckBox"
        showSelectAll={true}
        showDropDownIcon={true}
        filterBarPlaceholder={t("dashboards.retail.searchStores")}
        allowCustomValue={true}
        enablePersistence={true}
        // value={stores}
        change={(e) => onSelectionChange(e.value)}
        popupHeight="350px">
          <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
      <ButtonComponent cssClass='e-flat e-primary'>{t("dashboards.retail.refreshData")}</ButtonComponent>
    </SimpleEmptyCard>
  );
});
