import React, { useState, useRef, useCallback, useEffect} from "react";
import {
  AppBarComponent,
  MenuComponent,
  MenuItemModel,
} from "@syncfusion/ej2-react-navigations";
import { DropDownButtonComponent, MenuEventArgs } from "@syncfusion/ej2-react-splitbuttons";
import { DropDownListComponent, SelectEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserMenu from "../Menus/UserMenu/UserMenu";
import { useNavigate } from 'react-router-dom';
import { useSignOut } from "react-auth-kit";

import { useAppDispatch, useAppSelector } from '../../Hooks/hooks';
import { clearUser, UserData } from '../../state/login/userSlice';
import { setTheme, ThemeData } from '../../state/themes/themeSlice';
import { persistor } from '../../state/store';
import { SynAppValueSetting, updateValueSetting } from "../../api/synSettingsAPI";
import { PageHeaderParent, SynAppValueSettingType } from "../../Common/Enums";

interface AppbarProps {
  onToggleSidebar: () => void;
}

interface ThemeItem {
  id: string,
  theme: string
}

const Appbar: React.FC<AppbarProps> = ({ onToggleSidebar }) => {
  const { t, i18n } = useTranslation();
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('light');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const signOut = useSignOut();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.user);

  const themeData = useAppSelector(state => state.theme);
  useEffect(() => {
    if (themeData){
      setCurrentTheme(themeData.theme);
    }
  }, [themeData]);


  const toggleUserMenu = () => {
    setMenuVisibility(!isMenuVisible);
  };

  let userItems = [
    {
      iconCss: "e-icons e-settings",
      text: i18n.t("appbar.userAccount"),
      id: "userAccount"
    },
    {
      iconCss: "e-icons e-close",
      text: i18n.t("appbar.logout"),
      url: "/login"
    }
  ]

  const handleMenuItemSelect = (args: MenuEventArgs) => {
    const selectedItem = args.item;
    if (selectedItem.text === 'Logout') {
      signOut();
      dispatch(clearUser());
      persistor.purge();
      navigate('/login');
      // navigate('/');
    } else if (selectedItem.id === 'userAccount') {
      navigate('/user-account');
    }
  };

  //Theme ddl items
  const themes: {[key: string]: Object}[] = [
    {id: 'light', theme: i18n.t('themes.light')},
    {id: 'dark', theme: i18n.t('themes.dark')}
  ]
  //Theme ddl fields
  const fields: Object = { text: 'theme', value: 'id'};

  //Handles ddl theme select
  const themeSelect = useCallback((args: SelectEventArgs) => {

    const themeItem = args.itemData as unknown as ThemeItem;
    const themeData: ThemeData = {
      theme: themeItem.id
    }

    //Save user selected theme
    const themeToUpdate: SynAppValueSetting = {
      tenantId: userData.tenantId,
      userId: userData.id,
      settingType: SynAppValueSettingType.UserTheme,
      settingValue: JSON.stringify(themeData)
    }
    updateValueSetting(userData.tenant, themeToUpdate);
    dispatch(setTheme(themeData));
    // window.location.reload();
    }, []);

  return (
    <div className="w-full top-0">
      {/* Your header component goes here */}
      <AppBarComponent isSticky>
        <ButtonComponent cssClass="e-inherit" onClick={onToggleSidebar}>
          <span className="e-icons e-medium e-menu"></span>
        </ButtonComponent>
        <Link to="home">
          <ButtonComponent cssClass="e-inherit"><span className="e-icons e-medium e-home"></span></ButtonComponent>
        </Link>

        {/* <div className="e-folder">
                    <div className="e-folder-name">Navigation Pane</div>
                  </div> */}
        <div className="e-appbar-spacer"></div>

        <DropDownListComponent id='ddlThemes' width={70} dataSource={themes} 
                                   fields={fields} placeholder="Select a theme" value={currentTheme} select={themeSelect}
                                    />

        <ButtonComponent
          className="e-inherit menu ml-2"
          cssClass=""
          iconCss="e-icons e-medium e-settings"
        ></ButtonComponent>

        {/* <ButtonComponent
          onClick={toggleUserMenu}
          isPrimary
          cssClass="e-inherit menu ml-2"
          iconCss="e-icons e-menu"
        >
          {t("appbar.profile")}
        </ButtonComponent> */}

        <DropDownButtonComponent id="element" items={userItems} select={handleMenuItemSelect} iconCss='e-icons e-medium e-user'> {/* iconCss='e-icons e-medium e-user' */}
          {/* {" "} */}
          {/* {t("appbar.profile")} */}
        </DropDownButtonComponent>

        {/* <button ref={buttonRef} onClick={toggleUserMenu}>
        Show/Hide Dialog
      </button>
          <UserMenu isVisible={isMenuVisible} onClose={toggleUserMenu} buttonRef={buttonRef} /> */}
      </AppBarComponent>
    </div>
  );
};

export default Appbar;
