import axios from 'axios';
import apiClient, { createApiClient, RequestType } from './apiClient';
import { UserDataDto } from '../Dto/BaseDto';

interface LoginData {
    tenant: string;
    username: string;
    password: string;
}

const API_BASE_URL = process.env.REACT_APP_BASE_AUTH_URL ?? ''; //'https://localhost:44391/connect/token';
// const API_BASE_URL = 'https://localhost:44391/connect/token';
const API_CONNECT = 'connect/token';
const API_IDENTITY = 'api/identity';

export const login = async (loginData: LoginData): Promise<any> => {
    try {
        const requestData = {
            grant_type: 'password',
            scope: 'SynWBA',
            username: loginData.username,
            password: loginData.password,
            client_id: 'SynWBA_App',
          };

        const requestHeaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            __tenant: loginData.tenant,
            __requestType: '2'
        }
        
        const response = await axios.post(API_BASE_URL, requestData, { headers: requestHeaders});
        return response.data;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
}

export const getUserData = async(tenant: string, email: string): Promise<UserDataDto> => {
    const client = createApiClient('identity', tenant, RequestType.Host);
    const response = await client.get('/users/by-email/'+ email)
    const result: UserDataDto = response.data;

    return result;
}
