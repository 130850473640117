import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

interface ToastMessageProps {
    title?: string;
    message: string;
    timeOut: number;
    width?: number;
    height?: number;
    positionX?: string;
    positionY?: string;
}

export interface ToastMessageHandles {
    showToast: () => void;
}

const ToastMessage = forwardRef<ToastMessageHandles, ToastMessageProps>(({ title, message, timeOut, width = 230, height = 100, 
                                                                            positionX = "Right", positionY = "Top" }, ref) => {
    let toastInstance: ToastComponent;

    useImperativeHandle(ref, () => ({
        showToast() {
            if (toastInstance) {
                toastInstance.show({ title, content: message, timeOut });
            }
        }
    }));

    return (
        <ToastComponent
            id="customToast"
            ref={toast => toastInstance = toast!}
            position={{ X: "Right", Y: "Top" }}
            width={width}
            height={height}
            showCloseButton={true}
            showProgressBar={true}
        />
    );
});

export default ToastMessage;