import React from "react";
import * as ReactDOM from 'react-dom';
import { useState, useRef, useEffect, useCallback } from "react";
import { TreeViewComponent, SidebarComponent, NodeSelectEventArgs, NodeClickEventArgs} from "@syncfusion/ej2-react-navigations";
import "./sidebar.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ListBoxComponent, ChangeEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { useTranslation } from "react-i18next";

import { menuTreeItems } from "../../Data/menuData";

interface SidebarProps {
  isSidebarOpen: boolean;
  mediaQuery: string;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, mediaQuery }) => {
  const { t } = useTranslation();
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  
  const treeObjRef = useRef<TreeViewComponent | null>(null);
  // let field: Object = {
  //   dataSource: continentsData,
  //   id: "code",
  //   text: "name",
  //   child: "countries",
  // };
  // let style: string = "accordiontree";
  // const nodeSelect = useCallback((args: NodeSelectEventArgs) => {
  //   if (treeObjRef.current && args.node.classList.contains("e-level-1")) {
  //     // treeObjRef.current.collapseAll();
  //     treeObjRef.current.expandAll([args.node]);
  //     treeObjRef.current.expandOn = "None";
  //   } else if (treeObjRef.current && treeObjRef.current.expandOn == "None") {
  //     // treeObjRef.current.collapseAll();
  //   }
  // }, []);

  // useEffect(() => {
  //   // Find the element by class
  //   const element = document.querySelector('.e-list-icon');
  //   console.log("element:" + element);
  //   if (element && element.parentNode) {
  //     console.log("this is parent node:" +element.parentNode);
  //       // Remove the element
  //       element.parentNode.removeChild(element);
  //       const element1 = document.querySelector('.e-list-icon');
  //   console.log("element:" + element1);
  //   }
  // }, []); 

  // const element = document.querySelector('.e-list-icon');
  //   console.log("element:" + element);
  //   if (element && element.parentNode) {
  //     console.log("this is parent node:" +element.parentNode);
  //       // Remove the element
  //       element.parentNode.removeChild(element);
  //       const element1 = document.querySelector('.e-list-icon');
  //   console.log("element:" + element1);
  //   }




  const width = "240px";
  const target = ".main-sidebar-content";

  // let fields:object = { text:"text", iconCss:"iconCss"}

  let menuTreeField: Object = {
    dataSource: menuTreeItems,
    id: 'nodeId',
    text: 'nodeText',
    child: 'nodeChild',
    expanded: 'expanded',
    iconCss: "iconCss",
    // icon: 'icon',
    // imageUrl: 'imageUrl',
    // cssClass: "cssClass"
  }
  // let treeObj: TreeViewComponent;


  const nodeTemplate = (data: any) => {
    // console.log(data);
    return (
        <div className="tree-item-wrapper">
            <span className={`tree-icon ${data.iconCss}`}></span>
            <span className="tree-text">{data.nodeText}</span>
        </div>
    );
};

  // const MyTreeView = () => {
  //   return (
  //       <TreeViewComponent 
  //           fields={menuTreeField}
  //           nodeTemplate={nodeTemplate}
  //           id="sidebarMenu" 
  //           nodeClicked={onNodeClick.bind(this)} 
  //           ref={(treeview) => {treeObjRef.current = treeview;}} 
  //           nodeSelected={menuNodeSelect}
  //           showCheckBox={false}
  //       />
  //   );
  // };

  // function onNodeSelect(args: NodeSelectEventArgs): void{
  //   // let nodeId = args.node.id.toString(); //args.data[0].id.toString();
  //   let nodeId = args.nodeData.id.toString();
  //   console.log('Id1:' + nodeId.toString());
  //   navigate(nodeId.toString(), {replace: false});
  // }

  function onNodeClick(args: NodeClickEventArgs): void{
    // console.log(args);
    // console.log('On click');
    // console.log(args.node);
    // console.log(treeObjRef.current);
    // if (args.node.ariaLevel == '1'){
    //   if (args.node.ariaExpanded == 'true'){        
    //     if (treeObjRef.current != null)
    //       treeObjRef.current.expandAll([args.node]);
    //   }
    //   else {
    //     if (treeObjRef.current != null)
    //       treeObjRef.current.collapseAll([args.node]);
    //   }
    // }


    if (treeObjRef.current && args.node.classList.contains("e-level-1")) {
      // treeObjRef.current.collapseAll();
     
      if (args.node.ariaExpanded == 'false'){
        treeObjRef.current.expandAll([args.node]);
      }
      else{
        treeObjRef.current.collapseAll([args.node]);
      }
      // treeObjRef.current.expandAll([args.node]);
      // treeObjRef.current.expandOn = "None";
    } else if (treeObjRef.current && treeObjRef.current.expandOn == "None") {
      // treeObjRef.current.collapseAll();
    }

    // console.log(args.node);
    const clickedNodeElement = args.node; // HTMLLIElement
    const clickedNodeId = clickedNodeElement.getAttribute('data-uid');

    if (clickedNodeId == null || clickedNodeId.startsWith('p')){
      return;
    }      
    
    navigate(clickedNodeId == null ? 'home' : clickedNodeId, {replace: false});
  }

  const menuNodeSelect = useCallback((args: NodeSelectEventArgs) => {
    // console.log('On select');
    // console.log(args.node);
    // console.log(treeObjRef.current);
    // if (treeObjRef.current && args.node.classList.contains("e-level-1")) {
    //   // treeObjRef.current.collapseAll();
    //   treeObjRef.current.expandAll([args.node]);
    //   treeObjRef.current.expandOn = "None";
    // } else if (treeObjRef.current && treeObjRef.current.expandOn == "None") {
    //   // treeObjRef.current.collapseAll();
    // }


    // if (treeObjRef.current && args.node.classList.contains("e-level-1")) {
    //   // treeObjRef.current.collapseAll();
     
    //   if (args.nodeData.expanded == false){
    //     treeObjRef.current.expandAll([args.node]);
    //   }
    //   else{
    //     treeObjRef.current.collapseAll([args.node]);
    //   }
    //   // treeObjRef.current.expandAll([args.node]);
    //   // treeObjRef.current.expandOn = "None";
    // } else if (treeObjRef.current && treeObjRef.current.expandOn == "None") {
    //   // treeObjRef.current.collapseAll();
    // }
  }, []);


  return (
    <>      
      <SidebarComponent
        id="sideTree"
        className="fixed sidebar-treeview mt-12 h-full w-64 sidebar"
        ref={sidebarRef}
        width={width}
        target={target}
        mediaQuery={mediaQuery}
        isOpen={isSidebarOpen}
      >      
        
        {/* <div>
          <TreeViewComponent id="sidebarMenu" 
                             fields={menuTreeField} 
                             nodeClicked={onNodeClick.bind(this)} 
                             ref={(treeview) => {treeObjRef.current = treeview;}} 
                             nodeSelected={menuNodeSelect}
                             showCheckBox={false} />
        </div> */}
        
          {/* <MyTreeView /> */}
          <TreeViewComponent 
            fields={menuTreeField}
            nodeTemplate={nodeTemplate}
            statelessTemplates={['nodeTemplate']}
            id="sidebarMenu" 
            nodeClicked={onNodeClick.bind(this)} 
            ref={(treeview) => {treeObjRef.current = treeview;}} 
            nodeSelected={menuNodeSelect}
            showCheckBox={false}            
        />
     
               
      </SidebarComponent>
    </>
  );
};

export default Sidebar;
