import { DashboardBasicDto, DashboardChartDto } from '../Dto/BaseDto';
import apiClient, { createApiClient, RequestType } from './apiClient';
import { getColorFromList, truncateString, formatNumber } from '../Utility/Utility';


export interface PartnerDto {
    din: string;
    partner_Name: string;
    partner_Street: string;
    partner_Id: string;
    partner_Phone: string;
    partner_Email: string;
    city_Name: string;
    city_ZipCode: string;
    int_CountryName: string;
}

export const getPartnersList = async(tenant: string): Promise<PartnerDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/partners/partners');
    const result: PartnerDto[] = response.data;
    return result;
  }