import React, { useState, useEffect, useRef } from "react";
import './offersList.scss';
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '../../../Hooks/hooks';

import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group } from '@syncfusion/ej2-react-grids';
import { Inject, Page, PageSettingsModel, Sort, SortSettingsModel, FilterSettingsModel } from '@syncfusion/ej2-react-grids';

import { getOffersList, OfferDto } from '../../../api/offersAPI';
import { GeneralDateRangeDto } from '../../../Dto/BaseDto'
import { PageHeaderParent, SynAppValueSettingType } from "../../../Common/Enums";

import { gridData } from '../../../Data/testData';



const OffersList = () => {
    const [t, i18n] = useTranslation();
    const [ offersList, setOffersList ] = useState<OfferDto[]>([]);

    const user = useAppSelector(state => state.user);
    // const dateRangeSetting = useAppSelector(state => state.settings.settings[1]);

    const dateRangeSetting = useAppSelector(state => state.settings.settings[SynAppValueSettingType.OffersListDateRange]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            
            if (dateRangeSetting) {
              const data: GeneralDateRangeDto = JSON.parse(dateRangeSetting.settingValue);
              const fromDate = new Date(data.fromDate);
              const toDate = new Date(data.toDate);
              const formattedFromDate = fromDate.toISOString();
              const formattedToDate = toDate.toISOString();
      
            //   if (fromDate <= new Date("2022-12-31")) {
            //     handleShowToast();
            //   }
              const results = await Promise.all([
                getOffersList(user.tenant, formattedFromDate, formattedToDate)
                
              ]);
              setOffersList(results[0]);
            }
      
            //   setDashboardData(prevState => ({
            //     ...prevState,
            //     currentDateRangeData: data,
            //     numberOfInvoicesData: results[0],
            //     salesInPeriod: results[1],
            //     netSalesInPeriod: results[2],
            //     salesVatInPeriod: results[3],
            //     offersNumberData: results[4],
            //     offersTotalData: results[5],
            //     offersRealizedNumberData: results[6],
            //     offersRealizedTotalData: results[7],
            //     topCustomersData: results[8] || [],
            //     topCategoriesData: results[9] || [],
            //     topProductsData: results[10] || [],
            //     salesByMonthData: results[11] || []
            //   }));
            // }
          } catch (error) {
            console.error(error);
            
          }
        };
        fetchData();
      }, [dateRangeSetting]);

    const pageSettings: PageSettingsModel = { pageSize: 50 }
    const sortSettings: SortSettingsModel = { columns: [
                            {field: 'id', direction: 'Ascending' }
                        ] };
    // const filterSettings = { columns: [
    //         { field: 'id', operator: 'equal', value: 2 }
    //     ] };
    const filterOptions: FilterSettingsModel = {
        type: 'Menu'
      };
    const dateFormatOptions = { type: 'date', format: 'MM.dd.yyyy' };
    const numberFormatOptions = { type: 'number', format: '.##' };
    // formatSettings: [{ name: 'Amount', format: 'C2', useGrouping: false, currency: 'EUR' }]
    const eurFormatOptions = { type: 'number', format: 'C2', currency:'EUR' };

    return (
        <>
        <GridComponent dataSource={offersList} allowPaging={false} height="100%" 
                       pageSettings={pageSettings} allowSorting={true} sortSettings={sortSettings} 
                       allowFiltering={true} filterSettings={filterOptions} >
            <ColumnsDirective>
                {/* <ColumnDirective field='id' width='100' textAlign="Right" maxWidth={20} headerText="ID" visible={false}/> */}
                <ColumnDirective field='offer_Number' width='100' textAlign="Right" maxWidth={20} headerText={t('documents.offersList.offerNumber')}/>
                <ColumnDirective field='id' width='100' textAlign="Right" maxWidth={20} headerText="ID" visible={false}/>
                <ColumnDirective field='offer_Date' width='100' maxWidth={30} type="date" format={dateFormatOptions} headerText={t('documents.offersList.offerDate')}/>
                <ColumnDirective field='customer_Id' width='100' textAlign="Right" maxWidth={20} headerText={t('documents.offersList.customerId')}/>
                <ColumnDirective field='customer_Name' width='100' headerText={t('documents.offersList.customerName')}/>                
                <ColumnDirective field='vat_InvoiceAmmount' width='100' textAlign="Right" maxWidth={40} type="number" format="C2"  headerText={t('documents.offersList.offerTotal')} />
                
                
            </ColumnsDirective>
            <Inject services={[Page, Sort, Filter, Group]}/>
        </GridComponent>
        </>
    )
}

export default OffersList;