import React, { useState } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './dateRangeDialog.scss';
import { useTranslation } from "react-i18next";

type DateRangeDialogProps = {
    visible: boolean,
    fromDate?: Date,
    toDate?: Date,
    onClose: () => void,
    onSubmit: (dateFrom: Date, dateTo: Date) => void,
}

const DateRangeDialog: React.FC<DateRangeDialogProps> = ({ visible, onClose, onSubmit, fromDate, toDate}) => {
    const [t, i18] = useTranslation();
    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(new Date());

    let dialogObj: any;
    const handleDateFromChange = (e: any) => setDateFrom(e.value);
    const handleDateToChange = (e: any) => setDateTo(e.value);

    const handleSubmit = () => {
        if (dateFrom && dateTo){
            onSubmit(dateFrom, dateTo);
        }

        onClose();
    }

    function getDateFrom() {
        if (fromDate) {
            return fromDate.toDateString();
        }        
        
        return new Date().toDateString();        
    }

    function getDateTo() {
        if (toDate) {
            return toDate.toDateString();
        }        
        
        return new Date().toDateString();        
    }

    const buttons: any = [{
        buttonModel: {
            content: 'Ok',
            isPrimary: true,
        },
        'click': () => {
            handleSubmit();
        }
      }, {
        buttonModel: {
            content: 'Cancel'
        },
        'click': () => {
            dialogObj.hide();
        }
      }];
    

    return (
        <DialogComponent 
                visible={visible} 
                header={t('components.dateRangeDialog.title')} 
                width='300px'     
                height='260px'        
                close={onClose}
                // closeOnEscape={true}
                showCloseIcon={false} 
                isModal={true}
                buttons={buttons}
                ref={dialog => dialogObj = dialog}
            >
            <div className="container">
                <form>
                    <div className='form-group'>
                        <label>{t('components.dateRangeDialog.dateFrom')}</label>
                        <DatePickerComponent format="dd.MM.yyyy" change={handleDateFromChange} value={new Date(getDateFrom())} />
                    </div>
                    <div className='form-group'>
                        <label>{t('components.dateRangeDialog.dateTo')}</label>
                        <DatePickerComponent format="dd.MM.yyyy" change={handleDateToChange} value={new Date(getDateTo())}/>
                    </div>
                </form>
                
                {/* <ButtonComponent type='submit' cssClass='submit-button' onClick={handleSubmit} content={t('components.dateRangeDialog.submit')} /> */}
                
            </div>
        </DialogComponent>
    )
}

export default DateRangeDialog;



