import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store';

export interface ThemeData {
    theme: string
}

const initialState: ThemeData = {
    theme: ''
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<ThemeData>) => {
            const {theme} = action.payload;
            state.theme = theme; 
        }
    }
});

export const { setTheme } = themeSlice.actions;
export const selectTheme = (state: RootState) => state.theme;
export default themeSlice.reducer;
