import React from 'react';
import './home.scss';

const Home: React.FC = () => {

    return (
        <>
        {/* <div className='h-40 bg-black'></div>
        <div className='h-40 bg-black mt-2'>
            
        </div> */}

        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        <p>Home Page!</p>
        </>
    )
}

export default Home;

