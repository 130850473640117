import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store';

export interface SettingData {
    tenantId: string,
    userId: string,
    settingType: number,
    settingValue: string
}

interface SettingsState {
    settings: { [key: string]: SettingData }
}

const initialState: SettingsState = {
    settings: {}
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSetting: (state, action: PayloadAction<SettingData>) => {
            const key = `${action.payload.settingType}`;
            state.settings[key] = action.payload;
        }
    }
})

export const { setSetting } = settingsSlice.actions;
export default settingsSlice.reducer;