import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base';
import './i18n';

import { AuthProvider } from 'react-auth-kit';
import { Provider } from 'react-redux';
import { store } from './state/store';


// import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
// import currencies from './Localization/currencies.json';
// import cagregorian from './Localization/ca-gregorian.json';
// import numbers from './Localization/numbers.json';
// import delimiters from './Localization/delimiters.json';

// setCulture('hr');
// setCurrencyCode('EUR');

// loadCldr(
//   require('cldr-data/supplemental/numberingSystems.json'),
//   require('cldr-data/main/fr-CH/ca-gregorian.json'),
//   require('cldr-data/main/fr-CH/numbers.json'),
//   require('cldr-data/main/fr-CH/timeZoneNames.json')
//    );

// setCulture('hr-HR');
// setCurrencyCode('EUR');

// // import * as EJ2_LOCALE from "../node_modules/@syncfusion/ej2-locale/src/de.json";
// // import {L10n, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
// import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
// import currencies from './Localization/currencies.json';
// // L10n.load({
// //     'fr-BE': {
// //        'Grid': {
// //              'EmptyRecord': 'Aucun enregistrement à afficher',
// //              'InvalidFilterMessage':'Données de filtrage invalides',
// //               'UnGroup': 'Cliquez ici pour dégrouper '
// //          }
// //      }
// // });


// Registering Syncfusion license key

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5ceXVTRGFdWEd+WEc=');

// registerLicense('Mjk1NTYyNkAzMjMwMmUzNDJlMzBKdHFtMkRsditOSTFQN3J4Qzhkc1dnL1EyZmI3aEpSbW5xZDE4WFM4d1NFPQ==;Mjk1NTYyN0AzMjMwMmUzNDJlMzBoNDRtNC9NTzI0N1Q2WDhhOUIwMlBicE9sVkY2a21kSklsM2YxaUVYeWk4PQ==;Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5iSHxad0BiUH5Ycn1XTg==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Sd0dqWHpfeXVTRGleWQ==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQd0RhUH5bcX1UQGNcWUA=;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdURiW3ZfdXVcR2ZfU0B2;Mjk1NTYzMkAzMjMwMmUzNDJlMzBvNStSR05ZSjU2VGRwYm5EWVNaSnV1RWxZZlhyazViOGVjMjNOVWxGWHBzPQ==;Mjk1NTYzM0AzMjMwMmUzNDJlMzBjK09BbVE1cFhGaWdwOXhJNkliTTVJR0NzcmN2UktDVmtiaWFSUSttSkFJPQ==;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQd0RhUH5bcX1UQGNUUEQ=;Mjk1NTYzNUAzMjMwMmUzNDJlMzBpRUhFdUsyZXlxRGRjc1h3Q2dUclh2RXdCZEF1bjdMeEtFUFlpV25HL1k0PQ==;Mjk1NTYzNkAzMjMwMmUzNDJlMzBkQXI1V2Vrb3lGcXV5dThIMDZJS0xjYUI5M0dhTS92cERmeXoweWxOM2drPQ==;Mjk1NTYzN0AzMjMwMmUzNDJlMzBvNStSR05ZSjU2VGRwYm5EWVNaSnV1RWxZZlhyazViOGVjMjNOVWxGWHBzPQ==');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <AuthProvider authType = {'cookie'}
                  authName={'_auth'}
                  cookieDomain={window.location.hostname}
                  cookieSecure={false}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
