import React from 'react';
import './simpleEmptyCard.scss';
import "../../../assets/themeStyles.scss";
import { useTheme } from "../../../Contexts/ThemeContext";

interface SimpleEmptyCardProps {
    backgroundLightColor?: string;
    backgroundDarkColor?: string;
    children?: React.ReactNode;
}

const SimpleCard: React.FC<SimpleEmptyCardProps> = ({ backgroundLightColor, backgroundDarkColor, children }) => {
    const theme = useTheme();

    return (
    <div className={`card-wrapper ${theme === 'dark' ? "simple-card-dark simple-card-background-dark" : "simple-card-light simple-card-background-light"}`} 
                    style={{ width: '100%', height: '100%', backgroundColor: `${theme === 'dark' ? backgroundDarkColor : backgroundLightColor}` }}>
        <div className="dashboard-card">
            {children}
        </div>
    </div>
    )
}

export default SimpleCard;