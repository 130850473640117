import React from 'react';
import './invoicesListParent.scss';
import { useTranslation } from "react-i18next";

import PageHeader from "../../../Components/PageWrapper/PageWrapper";
import PageWrapper from '../../../Components/PageWrapper/PageWrapper';
import { PageHeaderParent } from "../../../Common/Enums";
import InvoicesList from '../../../Pages/Documents/Invoices/InvoicesList';

const InvoicesListParent: React.FC = () => {
    const [t, i18n] = useTranslation();


  return (
    <PageWrapper title={t("documents.invoicesList.title")} 
                 pageHeaderParent={PageHeaderParent.InvoicesList}
                 displayRange={true} 
                 displaySettingsButton={true} 
                 settingsButtonTarget={''} >
      <InvoicesList />
    </PageWrapper>
  );
};

export default InvoicesListParent;