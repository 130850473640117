

const Test2 = () => {
    return (
        <div>
            Test 2 Page!!!
        </div>      
        
    )
}

export default Test2;