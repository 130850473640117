import * as React from 'react';

import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, DataLabel, BarSeries, ColumnSeries,
        Category, Legend, Tooltip, ILoadedEventArgs, ChartTheme, Highlight, AxisModel } from '@syncfusion/ej2-react-charts';
import { DashboardChartDto } from '../../../Dto/BaseDto';

import "../../../assets/themeStyles.scss";
import { useTheme } from "../../../Contexts/ThemeContext";

interface SeriesConfig {
    dataSource: any[];
    xName: string;
    yName: string;
    type: string; 
    name: string;
    // Add other properties as needed
}

interface ChartShellProps {
    seriesData: SeriesConfig[];
    primaryXAxis?: Object;
    primaryYAxis?: Object;
    chartProps?: Object;
    titleStyle?: Object;
    [key: string]: any;
}

// interface PrimaryXAxisConfig {
//     valueType: string;
//     majorGridLines: { width: number };
//     // Add other properties as needed
// }

// interface PrimaryYAxisConfig {
//     labelFormat: string;
//     title: string;
//     edgeLabelPlacement: string;
//     majorTickLines: { width: number };
//     lineStyle: { width: number };
//     // Add other properties as needed
// }

const ChartShell: React.FC<ChartShellProps> = ({ seriesData, primaryXAxis, primaryYAxis, chartProps, titleStyle }) => {
    const theme = useTheme();
    const currThemeColor: string = (theme === 'dark' ? 'white' : 'black');
    
    return (
        <div className={`card-wrapper ${theme === 'dark' ? "chart-card-dark" : "chart-card-light"}`} style={{ width: '100%', height: '100%' }}>
            <ChartComponent {...chartProps}
                primaryXAxis={{ ...primaryXAxis, majorGridLines: { width: 0 }, labelStyle: { color:`${currThemeColor}` } }} 
                primaryYAxis={{ ...primaryYAxis, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color:`${currThemeColor}` }}} 
                chartArea={{ border: { width: 0 } }} 
                tooltip={{ enable: true }}
                titleStyle={{...titleStyle, color:`${currThemeColor}`}}
                >
                <Inject services={[BarSeries, ColumnSeries, DataLabel, Category, Legend, Tooltip, Highlight]} />
                <SeriesCollectionDirective>
                    {seriesData.map((series, index) => (                    
                        <SeriesDirective key={index} {...series} />
                    ))}
                </SeriesCollectionDirective>
                {/* Other common chart configurations */}
            </ChartComponent>
        </div>
    )
} 

export default ChartShell;