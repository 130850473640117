import React from "react";
import './userAccount.scss';

const UserAccount = () => {

    return (
        <>
            This is UserAccount page!
        </>
    )
}

export default UserAccount;