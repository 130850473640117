export enum DateRange {
    Today = 1,
    Yesterday = 2,
    ThisWeek = 3,
    LastWeek = 4,
    ThisMonth = 5,
    LastMonth = 6,
    ThisQuarter = 7,
    LastQuarter = 8,
    ThisYear = 9,
    LastYear = 10,
    Custom = 11
}

export function getDateRange(period: DateRange): { startDate: Date, endDate: Date } {
    const now = new Date();
    let startDate: Date;
    let endDate: Date = new Date(now);

    switch (period) {
        case DateRange.Today:
            startDate = new Date(now);
            break;
        case DateRange.Yesterday:
            startDate = new Date(now);
            startDate.setDate(startDate.getDate() - 1);
            endDate = new Date(startDate);
            break;
        case DateRange.ThisWeek:
            startDate = new Date(now);
            startDate.setDate(now.getDate() - (now.getDay() || 7) + 1);
            break;
        case DateRange.LastWeek:
            startDate = new Date(now);
            startDate.setDate(now.getDate() - (now.getDay() || 7) - 6);
            endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + 6);
            break;
        case DateRange.ThisMonth:
            startDate = new Date(now.getFullYear(), now.getMonth(), 2);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
            break;
        case DateRange.LastMonth:
            startDate = new Date(Date.UTC(now.getFullYear(), now.getMonth() - 1, 1));
            endDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 0));
            break;
        case DateRange.ThisQuarter:
            const currentQuarter = Math.floor(now.getMonth() / 3);
            startDate = new Date(Date.UTC(now.getFullYear(), currentQuarter * 3, 1));
            break;
        case DateRange.LastQuarter:
            const lastQuarter = Math.floor(now.getMonth() / 3) - 1;
            startDate = new Date(Date.UTC(now.getFullYear(), lastQuarter * 3, 1));
            endDate = new Date(Date.UTC(now.getFullYear(), lastQuarter * 3 + 3, 0));
            break;
        case DateRange.ThisYear:
            startDate = new Date(Date.UTC(now.getFullYear(), 0, 1));
            break;
        case DateRange.LastYear:
            startDate = new Date(Date.UTC(now.getFullYear() - 1, 0, 1));
            endDate = new Date(Date.UTC(now.getFullYear(), 0, 0));
            break;
        case DateRange.Custom:
            startDate = new Date();
            break;
    }

    return { startDate, endDate };
}

export const formatDateForAPI = (date: Date) => {
    return date.toISOString().split('T')[0]; // Gets the date part in 'YYYY-MM-DD' format
};

export function formatShortDate(date: Date): string {
    return new Intl.DateTimeFormat(navigator.language).format(date);
}

export const getColorFromList = (index: number): string => {
    const colors = [
      '#ff6384', '#36a2eb', '#cc65fe', '#ffce56', '#4bc0c0', 
      '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', 
      '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', 
      '#8bc34a', '#cddc39', '#ffc107', '#ff9800', '#ff5722' 
  ];
    return colors[index % colors.length]; 
  };

export const truncateString = (str: string, num: number): string => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
};

export const getPeriodTranslationString = (period: DateRange): string => {
    let result: string = 'pageHeader.filterPeriods.';

    switch (period) {
        case DateRange.Today:
            result += 'today';
            break;
        case DateRange.Yesterday:
            result += 'yesterday';
            break;
        case DateRange.ThisWeek:
            result += 'thisWeek';
            break;
        case DateRange.LastWeek:
            result += 'lastWeek';
            break;
        case DateRange.ThisMonth:
            result += 'lastWeek';
            break;
        case DateRange.LastMonth:
            result += 'lastMonth';
            break;
        case DateRange.ThisQuarter:
            result += 'thisQuarter';
            break;
        case DateRange.LastQuarter:
            result += 'lastQuarter';
            break;
        case DateRange.ThisYear:
            result += 'thisYear';
            break;
        case DateRange.LastYear:
            result += 'lastYear';
            break;
        case DateRange.Custom:
            result += 'custom';
            break;
        default:
            result = "";
    }

    return result;
}

export function formatNumber(number: number, options?: Intl.NumberFormatOptions): string {
    console.log(number);
    // Use the browser's current language setting or a default value.
    const userLocale = navigator.language || 'default';
    // Create a new Intl.NumberFormat instance with the user's locale and the provided options.
    const formatter = new Intl.NumberFormat(userLocale, {
      style: 'decimal', // Default style, can be overridden by options.
      minimumFractionDigits: 2, // Default value, can be overridden by options.
      ...options, // Spread any additional options passed to the function.
    });

    console.log(formatter.format(number))
    return formatter.format(number);
  }