import { DashboardBasicDto, DashboardChartDto } from '../Dto/BaseDto';
import apiClient, { createApiClient, RequestType } from './apiClient';
import { getColorFromList, truncateString, formatNumber } from '../Utility/Utility';


export interface InvoiceDto {
    din: string;
    invoice_Number: number;
    invoice_Date: string;
    customer_Name: string;
    id: number;
    vat_InvoiceAmmount: number;
}

export const getInvoicesList = async(tenant: string, fromDate: string, toDate: string): Promise<InvoiceDto[]> => {
    const client = createApiClient('app', tenant, RequestType.Tenant);
    const response = await client.get('/invoices/invoices?fromDate=' + fromDate + '&toDate=' + toDate);
    const result: InvoiceDto[] = response.data;
    return result;
  }

// import apiClient from './apiClient';

// interface Item {
//     Id: number;
// }

//   export const getNumberOfInvoices = async(year: number): Promise<Record<number, number> | undefined> => {
//     const response = await apiClient.get('/invoices/numberOfInvoices?year=' + year);

//     return response.data;
//   }

//   // export const getSalesInPeriod = async(fromDate: Date, toDate: Date): Promise<Record<number, number> | undefined> => {
    
//   // }