import React from 'react';
import Test from './Pages/Test';
import Test2 from './Pages/Test2';
import Home from './Pages/Home/Home';
import UserAccount from './Pages/UserAccount/UserAccount';
import SalesDashboard from './Pages/Dashboards/Sales/SalesDashboard';
import RetailDashboard from './Pages/Dashboards/Retail/RetailDashboard';
import OffersListParent from './Pages/Documents/Offers/OffersListParent';
import InvoicesListParent from './Pages/Documents/Invoices/InvoicesListParent';
import PartnersListParent from './Pages/Documents/Partners/PartnersListParent';

export const appRoutes = [
  { path: '/sales-dashboard', element: <SalesDashboard /> },
    { path: '/retail-dashboard', element: <RetailDashboard /> },
    { path: '/test2', element: <Test2 /> },
    { path: '/home', element: <Home /> }, 
    { path: '/user-account', element: <UserAccount /> }, 
    { path: '/offers-list', element: <OffersListParent /> }, 
    { path: '/invoices-list', element: <InvoicesListParent /> }, 
    { path: '/partners-list', element: <PartnersListParent /> }, 
  ];