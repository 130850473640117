import React from 'react';
import './partnersListParent.scss';
import { useTranslation } from "react-i18next";

import PageHeader from "../../../Components/PageWrapper/PageWrapper";
import PageWrapper from '../../../Components/PageWrapper/PageWrapper';
import { PageHeaderParent } from "../../../Common/Enums";
import PartnersList from '../../../Pages/Documents/Partners/PartnersList';

const PartnersListParent: React.FC = () => {
    const [t, i18n] = useTranslation();


  return (
    <PageWrapper title={t("documents.partnersList.title")} 
                 pageHeaderParent={PageHeaderParent.PartnersList}
                 displayRange={false} 
                 displaySettingsButton={false} 
                 settingsButtonTarget={''} >
      <PartnersList />
    </PageWrapper>
  );
};

export default PartnersListParent;