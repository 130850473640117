export enum PageHeaderParent {
    None = 0,
    SalesDashboard = 1,
    RetailDashboard = 2,
    OffersList = 3,
    InvoicesList = 4,
    PartnersList = 5,
}

export enum SynAppValueSettingType
{
    General,
    SalesDashboardDateRange,
    UserTheme,
    RetailDashboardDateRange,
    None,
    SelectedRetailStores,
    OffersListDateRange,
    InvoicesListDateRange,
}
